import {style, animate, trigger, transition, state} from '@angular/animations'

export const circleExpandAnimation = trigger('circleExpand', [
  state('void', style({opacity: 0})),
  transition(':enter', [
    animate('0.3s 0.1s ease-out', style({opacity: 1})),
    animate('0.3s', style({width: '5000px', height: '5000px'})),
    animate('0.2s', style({backgroundColor: 'white', opacity: 0}))
  ])
])
