import {ChangeDetectorRef, Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core'
import {ProfileService} from '@core/services/profile.service'
import {solveQuadratic} from '@core/utils/solve-quadratic'
import {IconComponent} from '@shared/components/icon.component'
import {InsetsDirective} from '@shared/directives/insets.directive'
import {ImageFallbackDirective} from '@shared/directives/img-fallback.directive'
import {AppState} from '../../../app.state'
import {AvatarComponent} from '@shared/components/avatar.component'
import {RouterLink} from '@angular/router'
import {QuitDirective} from '@shared/directives/quit.directive'

@Component({
  standalone: true,
  imports: [InsetsDirective, IconComponent, AvatarComponent, ImageFallbackDirective, RouterLink, QuitDirective],
  templateUrl: './waitlist.component.html',
})
export class WaitlistComponent {
  @ViewChild('header') headerElement!: ElementRef
  @ViewChild('content') contentElement!: ElementRef

  public sizes = []
  public isScrolled = false
  private scrollThreshold = 100

  constructor(
    public profileService: ProfileService,
    public state: AppState,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
  ) {
    this.state.sendAmplitudeEvent('quiz_pupil_enter')
  }

  ngAfterViewInit() {
    this.checkScroll()
    const height = this.contentElement.nativeElement.clientHeight
    let length = solveQuadratic(4, 72, 24, height) + 1
    this.sizes = Array.from({length}, (_, i) => 24 + (72 + 4 * i) * i)
    this.sizes.shift()
    this.cd.detectChanges()
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.checkScroll()
  }

  private checkScroll() {
    const scrollPosition = window.pageYOffset

    if (scrollPosition > this.scrollThreshold) {
      if (!this.isScrolled) {
        this.renderer.removeClass(this.headerElement.nativeElement, 'backdrop-blur-lg')
        this.renderer.addClass(this.headerElement.nativeElement, 'bg-primary-700')
        this.isScrolled = true
      }
    } else {
      if (this.isScrolled) {
        this.renderer.addClass(this.headerElement.nativeElement, 'backdrop-blur-lg')
        this.renderer.removeClass(this.headerElement.nativeElement, 'bg-primary-700')
        this.isScrolled = false
      }
    }
  }
}
