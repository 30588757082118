<div class="page">

	@if (state.question) {
		<div insets [paddingTop]="true" [@opacityAnimation]="state.question" class="flex flex-col justify-center bg-white">
			<div class="flex items-center justify-between w-full max-w-xl px-3 py-2 mx-auto z-10">
				@if (countdown > 0) {
					<div
						class="flex gap-1 items-center rounded-full px-2 py-1 transition-all"
						[class.bg-gray-100]="countdown > 10"
						[class.bg-red-500]="countdown <= 10"
						[class.outline]="countdown <= 10"
						[class.outline-4]="countdown <= 10"
						[class.outline-red-100]="countdown <= 10"
						[class.text-white]="countdown <= 10"
					>
						<mat-icon class="text-[18px]">timer</mat-icon>
						<span class="font-bold">{{countdown}}</span>
					</div>
				} @else {<div></div>}
				<button quit class="flex gap-1 items-center justify-center text-center bg-[#EC2222] hover:bg-[#EC2222] text-white py-1 px-4 rounded text-[14px] transition-all">
					<mat-icon class="text-sm">logout</mat-icon>
					<span class="font-normal">Выйти</span>
				</button>
			</div>
			<div class="relative w-full h-1 top-0">
				<div class="absolute left-0 right-0 top-0 w-full h-1 bg-primary-100 z-0"></div>
				@if (countdown > 0) {
					<div class="absolute left-0 top-0 h-1 bg-primary-700 rounded-r z-10 transition-all ease-linear" [style.width.%]="100 * countdown / duration"></div>
				}
			</div>
		</div>
	}

	@if (countdown > 0) {
		<ng-content></ng-content>
	} @else {
		@if (state.question) {
			<div insets [paddingBottom]="true" [@opacityAnimation]="state.question" class="page-body max-w-xl mx-auto">
				<div class="flex flex-col gap-5 p-4">
					@if (state.correctAnswer) {
						@if (state.correctAnswer.id === state.selectedAnswer?.id) {
							<div @opacityAnimation class="flex items-center bg-[#4CCE17] rounded-xl p-4 w-full max-w-xl overflow-hidden">
								<div class="w-6 h-6 rounded-full bg-white outline-8 outline-red-300">
									<mat-icon class="font-extrabold text-2xl leading-6 text-[#4CCE17]">check</mat-icon>
								</div>
								<span class="flex-1 text-center text-white font-medium">Верно</span>
							</div>
						} @else {
							<div @opacityAnimation class="flex items-center bg-[#EC2222] rounded-xl p-4 w-full max-w-xl overflow-hidden">
								<div class="w-6 h-6 rounded-full bg-white">
									<mat-icon class="font-extrabold text-2xl leading-6 text-[#EC2222]">close_small</mat-icon>
								</div>
								<span class="flex-1 text-center text-white font-medium">Неверно:(</span>
							</div>
						}
					}

					@if(state.question.explanation) {
						<div @opacityAnimation class="bg-[#F0F2FD] flex flex-col gap-4 justify-between items-start p-4 rounded-xl w-full max-w-xl">
							<div class="p-1 w-7 h-7 rounded-full bg-white shadow-lg">
								<mat-icon class="text-[20px] leading-5 text-primary-700">lightbulb</mat-icon>
							</div>
							<div [innerMathjaxHTML]="state.question.explanation"></div>
						</div>
					}
				</div>
			</div>
		}
	}

</div>
