import {AfterViewInit, Component} from '@angular/core'
import {HeaderTeacherComponent} from "../header/header.component"
import {ActivatedRoute, Router, RouterLink, RouterOutlet} from "@angular/router"
import {firstValueFrom} from "rxjs"
import {AppState} from "../../../app.state"
import {InsetsDirective} from "@shared/directives/insets.directive"
import {QuizPlayer} from "@quiz"

@Component({
  standalone: true,
  imports: [HeaderTeacherComponent, RouterOutlet, RouterLink, InsetsDirective],
  templateUrl: './statistics.component.html',
})
export class StatisticsComponent implements AfterViewInit {
  math = Math
  page: 'ratings' | 'questions' = 'ratings'

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public state: AppState
  ) { }

  ngAfterViewInit(): void {
    this.activatedRoute.params.subscribe(async ({guid}) => {
      if (guid) {
        await firstValueFrom(this.state.getStatistics(guid))
      }
    })
  }

  get correct(): number {
    if (!this.state.statistics) return 50
    if (this.state.statistics.correct + this.state.statistics.wrong === 0) return 50
    return 100 * this.state.statistics.correct / (this.state.statistics.correct + this.state.statistics.wrong)
  }

  get wrong(): number {
    if (!this.state.statistics) return 50
    if (this.state.statistics.correct + this.state.statistics.wrong === 0) return 50
    return 100 * this.state.statistics.wrong / (this.state.statistics.correct + this.state.statistics.wrong)
  }

  get route(): string {
    const url = this.router.url.split('/')
    return url[url.length - 1]
  }
}
