import {Injectable} from '@angular/core'
import {BehaviorSubject} from 'rxjs'

const DEFAULT_TIME = 3

@Injectable({providedIn: 'root'})
export class TimerService {
  private timerSubject = new BehaviorSubject<number>(DEFAULT_TIME);
  timer$ = this.timerSubject.asObservable();

  private intervalId: any

  startTimer() {
    this.resetTimer()
    this.intervalId = setInterval(() => this.timerSubject.next(this.timerSubject.value - 1), 1000)
  }

  stopTimer() {
    if (this.intervalId) clearInterval(this.intervalId)
  }

  resetTimer() {
    this.stopTimer()
    this.timerSubject.next(DEFAULT_TIME)
  }
}
