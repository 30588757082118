<div class="page">

	<header-teacher/>

	<div insets [paddingBottom]="true" class="page-body w-full max-w-xl mx-auto">

		<div class="p-4">

			@if (state.statistics) {
				<div class="relative justify-between p-3 rounded-xl border bg-[#F8F8F8] border-[#DDD] mb-10">
					<div class="flex flex-row gap-3 w-full justify-between h-10 overflow-x-visible">
						<div class="rounded-md bg-[#4CCE17] min-w-2 h-10 transition-all duration-[1.5s] ease-in-out" [style.width.%]="correct"></div>
						<div class="flex flex-col gap-1 left-10 w-[100px] min-w-[100px] min-h-[88px] mt-[-24px] rounded-3xl bg-white px-1 pt-5 pb-3 shadow transition-all duration-[1.5s] ease-in-out">
							<span class="text-3xl leading-7 text-nowrap font-bold text-center transition-all">{{ state.statistics.correct + state.statistics.wrong !== 0 ? math.trunc(100 * state.statistics.correct / (state.statistics.correct + state.statistics.wrong)) : 0 }} %</span>
							<span class="text-[9px] leading-[12px] text-center">Статистика ответов класса</span>
						</div>
						<div class="rounded-md bg-[#EC2222] min-w-2 h-10 transition-all duration-[1.5s] ease-in-out" [style.width.%]="wrong"></div>
					</div>
				</div>
			}

			<div class="flex items-center bg-[#F7F8FA] rounded-lg w-full mb-5">

				<div
					class="px-5 py-[10px] font-[500] text-[16px] rounded-lg flex-1 text-center cursor-pointer"
					routerLink="ratings"
					[class]="{
		        'bg-primary-700': route === 'ratings',
		        'text-white': route === 'ratings',
		      }"
				>
					Рейтинг
				</div>

				<div
					class="px-5 py-[10px] font-[500] text-[16px] rounded-lg flex-1 text-center cursor-pointer"
					routerLink="questions"
					[class]="{
		        'bg-primary-700': route === 'questions',
		        'text-white': route === 'questions',
		      }"
				>
					Вопросы
				</div>

			</div>

			<router-outlet/>

		</div>

	</div>

</div>
