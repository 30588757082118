import {Component} from '@angular/core'
import {circleExpandAnimation} from "@core/animations/circle-expand"
import {opacityAnimation} from "@core/animations/opacity"
import {startTimerAnimation} from "@core/animations/start-timer"
import {TimerService} from "@core/services/ui/timer.service"
import {Subscription} from 'rxjs'

@Component({
  selector: 'start-timer',
  standalone: true,
  animations: [opacityAnimation, startTimerAnimation, circleExpandAnimation],
  templateUrl: './timer.component.html',
  styleUrl: './timer.component.css'
})
export class TimerComponent {
  show: boolean = false
  time: number = 0

  private timerSubscription: Subscription

  constructor(
    private timerService: TimerService
  ) { }

  ngOnInit() {
    this.timerSubscription = this.timerService.timer$.subscribe((time) => {
      this.time = time
      this.show = time >= 0
    })
  }

  ngOnDestroy() {
    if (this.timerSubscription) this.timerSubscription.unsubscribe()
  }

}
