import {Dialog} from "@angular/cdk/dialog"
import {HttpEvent, HttpHandlerFn, HttpRequest} from "@angular/common/http"
import {inject} from "@angular/core"
import {DialogComponent} from "@shared/components/dialog/dialog.component"
import {catchError, Observable, throwError} from "rxjs"

export const extractHttpErrorMessage = (data: any) => {
  if (data?.detail?.[0]?.msg) {
    return data?.detail?.[0]?.msg
  }

  if (!data || typeof data === 'string') {
    return data
  }

  if (Array.isArray(data)) {
    return extractHttpErrorMessage(data[0])
  }

  const objKeys = Object.keys(data)
  return extractHttpErrorMessage(data[objKeys[0]])
}

export function alertInterceptor(request: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const dialog = inject(Dialog)

  return next(request).pipe(
    catchError((error) => {
      if (request.params.has('skip_error_handling')) return throwError(error)

      let message = ''

      if (error.status === 0) {
        message = request
          ? `Доступ к <code>${request.url}</code> заблокирован политикой CORS. Подробности смотрите в консоли браузера.`
          : `Доступ к HTTP-адресу заблокирован политикой CORS. Подробности смотрите в консоли браузера.`
      } else if (error.status >= 500) {
        message = 'Ошибка сервера'
      } else if (error.status === 404) {
        message = 'Не найдено'
      } else {
        message = extractHttpErrorMessage(error.error)
      }

      dialog.open(DialogComponent, {
        hasBackdrop: false,
        disableClose: true,
        data: {
          message: message,
          delay: 5000,
          messageClass: 'p-4 bg-white bg-red-100 text-red-500 font-medium rounded-xl text-center shadow-top'
        }
      })

      return throwError(error)
    })
  )
}
