import {Pipe, PipeTransform} from '@angular/core'
import moment from 'moment'

@Pipe({
  standalone: true,
  name: 'duration',
})
export class DurationPipe implements PipeTransform {
  transform(duration: string, format: string = 'HH:mm'): string {
    return moment.utc(moment.duration(duration).asMilliseconds()).format(format)
  }
}
