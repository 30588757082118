import amplitude from 'amplitude-js'

import {environment} from '@env'
import {rnLog} from '@core/utils/rn-webview'

export const sendAmplitudeEvent = (event: string, params?: any) => {
  if (!environment.production) return
  rnLog(`[Amplitude] Event "${event}" with params ${JSON.stringify(params)}`)
  amplitude.getInstance().logEvent(event, params)
}
