import {Component, OnDestroy, OnInit} from '@angular/core'
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router'
import {firstValueFrom} from 'rxjs'

import {Quiz, QuizStatus, QuizTest} from '@quiz'
import {AppService} from '@core/services/app.service'
import {ProfileService} from '@core/services/profile.service'
import {CentrifugoService} from '@core/services/centrifugo.service'
import {InsetsDirective} from '@shared/directives/insets.directive'
import {AppState} from '../../app.state'

@Component({
  standalone: true,
  imports: [RouterOutlet, InsetsDirective],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
})
export class MainComponent implements OnDestroy, OnInit {

  guid: string
  quiz: Quiz
  test: QuizTest

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private centrifugoService: CentrifugoService,
    private profileService: ProfileService,
    private app: AppService,
    private state: AppState,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(async ({guid}) => {
      this.guid = guid
      await firstValueFrom(this.state.getQuiz(guid))
      if (this.guid) {
        this.app.initialized$.subscribe(inited => {
          if (inited) this.centrifugoService.connect()
        })
      }
    })

    this.state.quiz$.subscribe(quiz => {
      if (!quiz) return

      if (!this.profileService.user) return

      if (this.profileService.isStudent) {
        switch (quiz.status) {
          case QuizStatus.PUBLISHED:
          case QuizStatus.READY:
            this.router.navigate([`/${quiz.guid}/waitlist`], {replaceUrl: true})
            break
          case QuizStatus.LIVE:
            this.router.navigate([`/${quiz.guid}/test`], {replaceUrl: true})
            break
          case QuizStatus.FINISHED:
            this.router.navigate([`/${quiz.guid}/results`], {replaceUrl: true})
            break
        }

        return
      }

      if (this.profileService.isTeacher) {
        switch (quiz.status) {
          case QuizStatus.PUBLISHED:
            if (this.state.quiz.status != QuizStatus.FINISHED) this.state.sendAmplitudeEvent('quiz_lesson_prepared')
            this.router.navigate([`/${quiz.guid}/`], {replaceUrl: true})
            break
          case QuizStatus.READY:
            this.router.navigate([`/${quiz.guid}/start`], {replaceUrl: true})
            break
          case QuizStatus.LIVE:
            this.router.navigate([`/${quiz.guid}/statistics`], {replaceUrl: true})
            break
        }

        return
      }
    })
  }

  ngOnDestroy(): void {
    this.centrifugoService.unsubscribeQuiz(this.guid)
    this.centrifugoService.unsubscribePersonal()
    this.centrifugoService.disconnect()
  }
}
