import {style, animate, trigger, transition, state} from '@angular/animations'

export const hideVerticalAnimation = trigger('hide-vertical', [
  transition(':enter', [
    style({opacity: 0, height: 0}),
    animate('200ms', style({opacity: 1, height: 'inherit'})),
  ]),
  transition(':leave', [
    animate('200ms', style({opacity: 0, height: 0}))
  ])
])
