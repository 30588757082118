import {ChangeDetectorRef, Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core'
import {openCloseAnimation} from "@core/animations/open-close"
import {TimerComponent} from "../../../core/timer/timer.component"
import {IconComponent} from "@shared/components/icon.component"
import {InsetsDirective} from "@shared/directives/insets.directive"
import {RouterLink} from "@angular/router"
import {QuitDirective} from '@shared/directives/quit.directive'

@Component({
  standalone: true,
  selector: 'header-teacher',
  animations: [openCloseAnimation],
  templateUrl: './header.component.html',
  imports: [TimerComponent, IconComponent, InsetsDirective, RouterLink, QuitDirective],
})
export class HeaderTeacherComponent {
  @ViewChild('header') headerElement!: ElementRef
  public isScrolled = false
  private scrollThreshold = 100

  constructor(
    private renderer: Renderer2,
    private cd: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.checkScroll()
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.checkScroll()
  }

  private checkScroll() {
    const scrollPosition = window.pageYOffset

    if (scrollPosition > this.scrollThreshold) {
      if (!this.isScrolled) {
        this.renderer.removeClass(this.headerElement.nativeElement, 'bg-primary-700')
        this.renderer.addClass(this.headerElement.nativeElement, 'backdrop-blur-lg')
        this.isScrolled = true
      }
    } else {
      if (this.isScrolled) {
        this.renderer.addClass(this.headerElement.nativeElement, 'bg-primary-700')
        this.renderer.removeClass(this.headerElement.nativeElement, 'backdrop-blur-lg')
        this.isScrolled = false
      }
    }
    this.cd.detectChanges()
  }
}
