<div class="fixed top-0 max-w-xl w-full h-screen mx-auto left-[50%] -translate-x-[50%] z-0 bg-leaderboard">

  <div class="absolute -left-28 -top-12 blur-[76px] w-[478px] h-[478px] bg-[#514EFF] rounded-full"></div>
  <div class="absolute -right-40 top-[50%] -translate-y-[50%] blur-[76px] w-[478px] h-[478px] bg-[#7C4EFF] rounded-full"></div>
  <div class="absolute -left-32 -bottom-12 blur-[76px] w-[478px] h-[478px] bg-[#3699FB] rounded-full"></div>

  <div class="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] flex justify-center items-center rounded-full px-[28px] py-4 bg-white outline outline-[rgb(255,255,255,0.1)] outline-[48px]">
    @if (time === 0) {
      <span class="text-[36px] font-extrabold text-[#5552EE] leading-[36px] text-center align-middle">Kettik!</span>
    } @else {
      <span class="text-[64px] font-extrabold text-[#5552EE] leading-[64px] w-[64px] text-center align-middle my-3">{{time}}</span>
    }
  </div>

</div>
