import {Component} from '@angular/core'
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router'
import {filter, switchMap} from 'rxjs'
import amplitude from 'amplitude-js'

import {rnNavigate} from '@core/utils/rn-webview'
import {AppState} from './app.state'
import {environment} from '@env'

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  providers: [AppState],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.initAmplitude()
    this.initNavigationChange()
  }

  initAmplitude(): void {
    if (!environment.production) return
    amplitude.getInstance().init('f9d845ca1c69a03330507ce7273d6d46')
  }

  initNavigationChange(): void {
    this.router.events
      .pipe(
        filter((e: any) => e instanceof NavigationEnd),
        switchMap(() => this.activatedRoute.children),
        switchMap(children => children.firstChild.data),
      )
      .subscribe(data => {
        let payload = {
          canGoBack: true,
          scrollEnabled: false,
          tabBarVisible: false,
          orientation: 'lockToPortrait',
        }

        if (data['isRoot']) {
          payload = {...payload, canGoBack: false}
        }

        rnNavigate(payload)
      })
  }
}
