const http_protocol = 'https://'
const ws_protocol = 'wss://'
const domain = 'app.dev.joo.kz'
const api_base_url = `${http_protocol}${domain}`
const api_v1_url = `${api_base_url}/api/v1`
const api_v2_url = `${api_base_url}/api/v2`

export const environment = {
  production: false,
  api_base_url,
  api_v1_url,
  api_v2_url,
  api_hs_sms_url: `${api_v2_url}/highschool/sms`,
  api_hs_lms_url: `${api_v2_url}/highschool/lms`,
  api_hs_quiz_url: `${api_v2_url}/highschool/quiz`,
  api_hs_quiz_centrifugo: `${ws_protocol}${domain}/api/v2/highschool/connection/websocket`
}
