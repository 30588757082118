import {DIALOG_DATA, DialogRef} from "@angular/cdk/dialog"
import {Component, inject, OnInit} from '@angular/core'
import {InsetsDirective} from "@shared/directives/insets.directive"
import {rnNavigateBack} from '@core/utils/rn-webview'

@Component({
  standalone: true,
  styleUrl: 'dialog.component.css',
  imports: [InsetsDirective],
  template: '<div insets [marginBottom]="true" [class]="messageClass">{{message}}</div>'
})
export class DialogComponent implements OnInit {
  dialogRef = inject(DialogRef)
  data = inject(DIALOG_DATA)

  message: string = ""
  messageClass: string = "p-3 bg-white rounded-xl shadow-top"
  delay: number | false = 5000
  onClose: () => void = () => { }

  ngOnInit(): void {
    if ('message' in this.data) this.message = this.data['message']
    if ('messageClass' in this.data) this.messageClass = this.data['messageClass']
    if ('delay' in this.data) this.delay = this.data['delay']
    if ('onClose' in this.data) this.onClose = this.data['onClose']

    if (this.delay) setTimeout(() => this.close(), this.delay)
  }

  close(): void {
    rnNavigateBack()
    this.dialogRef.close()
    this.onClose()
  }
}
