import {Component} from '@angular/core'
import {RouterLink} from "@angular/router"
import {DialogComponent} from "@shared/components/dialog/dialog.component"
import {IconComponent} from "@shared/components/icon.component"

@Component({
  standalone: true,
  imports: [IconComponent, RouterLink],
  templateUrl: './confirm.component.html',
  styleUrl: './confirm.component.css'
})
export class StartConfirmComponent extends DialogComponent {
  confirm: () => void

  ngOnInit(): void {
    if ('onConfirm' in this.data) this.confirm = this.data['onConfirm']
  }

  onConfirm() {
    this.confirm()
    this.dialogRef.close()
  }
}
