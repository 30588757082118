import {AfterViewInit, Component} from '@angular/core'
import {AppState} from '../../../app.state'
import {IconComponent} from '@shared/components/icon.component'
import {HeaderTeacherComponent} from '../header/header.component'
import {FormGroup} from '@angular/forms'
import {QuizMode} from '@quiz'
import {firstValueFrom} from 'rxjs'
import {CommonModule} from '@angular/common'
import {InsetsDirective} from '@shared/directives/insets.directive'
import {LMSService} from '@core/services/lms.service'
import {LessonAttendance, LessonAttendanceStatus} from '@core/schemas/attendance.model'
import {AvatarComponent} from '../../../shared/components/avatar.component'
import {ProfileService} from '@core/services/profile.service'

enum StudentStatus {
  ONLINE = 0,
  WAITLIST = 1,
  ABSENT = 2,
  KICKED = 3
}

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent, HeaderTeacherComponent, InsetsDirective, AvatarComponent],
  templateUrl: './activate.component.html',
})
export class ActivateComponent implements AfterViewInit {
  mode: QuizMode = QuizMode.SYNCHRONIZED
  formGroup: FormGroup
  attendances: LessonAttendance[] = []
  lessonAttendance: LessonAttendance[] = []

  attendance: any
  private initial_accepted_users: number[] = []
  private initial_absent_users: number[] = []

  accepted_users: number[] = []
  kicked_users: number[] = []
  absent_users: number[] = []
  online_users: number[] = []

  constructor(
    public state: AppState,
    private lmsService: LMSService,
    private profileService: ProfileService,
  ) {
  }

  async ngAfterViewInit(): Promise<void> {
    this.state.quiz$.subscribe(async (quiz) => {
      if (quiz && quiz.meta.school_id && quiz.meta.lesson_id && quiz.meta.group_id) {
        const students = await firstValueFrom(this.lmsService.listStudents(quiz.meta.school_id, quiz.meta.group_id))
        const studentMap = Object.fromEntries(students.map(s => [s.student_id, s]))
        const studentMapKeys = Object.keys(studentMap).map(s => +s)
        const attendance = await firstValueFrom(this.lmsService.listLessonAttendance(quiz.meta.school_id, quiz.meta.lesson_id))

        this.attendances = attendance.filter(a => studentMapKeys.includes(a.student_id))
          .filter(a => !!studentMap[a.student_id])
          .map(a => ({...a, online: false, student: studentMap[a.student_id], user_id: studentMap[a.student_id]?.user_id}))
          .sort((a, b) => b.status - a.status)

        this.accepted_users = this.attendances.filter(p => p.status == LessonAttendanceStatus.PRESENT).map(p => p.student.user_id)
        this.absent_users = this.attendances.filter(p => p.status == LessonAttendanceStatus.ABSENT).map(p => p.student.user_id)
        this.initial_accepted_users = this.initial_accepted_users
        this.initial_absent_users = this.absent_users
      }
    })

    this.state.onlineUsers$.subscribe(async (userIds) => {
      this.attendances = this.attendances.map((a) => ({...a, online: userIds.includes(a.student?.user_id)}))
    })
  }

  async kickPlayer(attendance: LessonAttendance) {
    const user_id = attendance.student.user_id
    this.accepted_users = this.accepted_users.filter(u => u !== user_id)

    if (this.initial_absent_users.includes(user_id)) {
      this.absent_users.push(user_id)
    } else {
      this.kicked_users.push(user_id)
    }
  }

  async restorePlayer(attendance: LessonAttendance) {
    const user_id = attendance.student.user_id
    this.accepted_users.push(user_id)
    this.absent_users = this.absent_users.filter(u => u !== user_id)
    this.kicked_users = this.kicked_users.filter(u => u !== user_id)
  }

  async onActivate() {
    const payload = {
      accepted_users: [...new Set(this.accepted_users)],
      kicked_users: [...new Set(this.kicked_users)],
      absent_users: [...new Set(this.absent_users)],
    }

    await firstValueFrom(this.state.activateQuiz(QuizMode.SYNCHRONIZED, payload))
    await firstValueFrom(this.state.listPlayers(this.state.quiz.guid))
    await firstValueFrom(this.state.getQuiz(this.state.quiz.guid))
  }
}
