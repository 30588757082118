import {style, animate, trigger, transition, keyframes} from '@angular/animations'

export const startTimerAnimation = trigger('startTimer', [
  transition(':increment', [
    style({fontSize: '96px'}),
    animate('300ms ease-out', style({opacity: 1, fontSize: '64px'})),
  ]),
  transition(':decrement', [
    animate('700ms ease-out', style({opacity: 0, fontSize: '64px'}))
  ])
])
