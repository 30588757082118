import {Injectable} from '@angular/core'
import {RouterStateSnapshot} from '@angular/router'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable, Subject, tap} from 'rxjs'
import {Moment} from 'moment'

import {environment} from '@env'
import {LangType} from '@core/types/lang.type'
import {TokenModel} from '@core/schemas/token.model'
import {ThemeType} from '@core/types/theme.type'
import {retrieveTokenExpireMoment} from '@core/utils/auth'
import {rnAlert, rnLog, rnNavigateBack, rnOnLoad} from '@core/utils/rn-webview'
import {RnRouteParamsService} from '@core/services/rn-route-params.service'
import {
  DEFAULT_INSETS,
  DEFAULT_LANG,
  DEFAULT_THEME,
  MOBILE_ACCESS_TOKEN,
  MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE,
  MOBILE_LANG,
  MOBILE_REFRESH_TOKEN,
  MOBILE_SAFE_AREA_INSETS,
  MOBILE_THEME,
  MOBILE_THEME_OBSERVABLE,
} from '@core/constants/constants'
import {ProfileService} from '@core/services/profile.service'

const hasRnToken = () => !!window[MOBILE_ACCESS_TOKEN]

@Injectable()
export class AppService {

  private _initialized = new BehaviorSubject<boolean>(false)
  private _lang: LangType
  private _theme: ThemeType = 'light'

  private _token: string
  private _refresh: string

  private _tokenExpireMoment: Moment

  constructor(
    private http: HttpClient,
    private profileService: ProfileService,
    private rnRouteParamsService: RnRouteParamsService,
  ) {
    this.initRnObservables()
  }

  startAppInit(state: RouterStateSnapshot) {
    const {login, phone, password} = state.root.queryParams

    if ((login || phone) && password) {
      const username = login ?? `+${phone}`

      return this.login(username, password).subscribe({
        error: () => alert('Couldn\'t login'),
        next: () => {
          this.profileService.getUser().subscribe({
            error: () => alert('Couldn\'t fetch user'),
            next: () => this._initialized.next(true),
          })
        },
      })
    }

    this.waitRnDataInjection()
      .then(() => {
        this.rnRouteParamsService.init()
        this.saveRnData()

        this.profileService.getUser().subscribe({
          next: () => this.finishAppInit(),
          error: () => {
            rnAlert('Error', 'Couldn\'t fetch user info')
            rnNavigateBack()
          },
        })
      })
      .catch(() => {
        rnAlert('Error', 'Couldn\'t login')
        rnNavigateBack()
      })
  }

  waitRnDataInjection(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (hasRnToken()) {
        rnLog('RN data was injected immediately.')
        resolve()
      }

      /**
       * Below code is used to prevent undefined values injected by RN.
       * There have been cases in the past when the values were only available after a while.
       * Therefore, to ensure that these values are successfully received, we use a timeout of five seconds.
       */

      let retry = 0
      const max = 10

      const interval = setInterval(() => {
        if (hasRnToken()) {
          rnLog('RN data was injected after sometime.')
          clearInterval(interval)
          resolve()
        } else if (retry === max) {
          rnLog('Timeout error since RN did not inject mobile data.')
          clearInterval(interval)
          reject()
        } else {
          rnLog(`[${retry}] Could not get RN data, retrying process...`)
          retry++
        }
      }, 50)
    })
  }

  saveRnData(): void {
    this.setTheme(window[MOBILE_THEME])
    this.setToken(window[MOBILE_ACCESS_TOKEN], window[MOBILE_REFRESH_TOKEN])
  }

  finishAppInit(): void {
    rnOnLoad()
    this._initialized.next(true)
  }

  initRnObservables(): void {
    window[MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE] = new Subject()
    this.onMobileAppFinishRefreshingToken$.subscribe((token: TokenModel) => this.setToken(token.access, token.refresh))

    window[MOBILE_THEME_OBSERVABLE] = new Subject<any>()
    this.onMobileAppChangedTheme$.subscribe((theme: ThemeType) => this.setTheme(theme))
  }

  login(username: string, password: string): Observable<TokenModel> {
    return this.http.post<TokenModel>(`${environment.api_v1_url}/identity/auth/token/`, {username, password}).pipe(
      tap(({access, refresh}) => {
        window[MOBILE_ACCESS_TOKEN] = access
        window[MOBILE_REFRESH_TOKEN] = refresh
        window[MOBILE_LANG] = DEFAULT_LANG
        window[MOBILE_THEME] = DEFAULT_THEME
        window[MOBILE_SAFE_AREA_INSETS] = DEFAULT_INSETS

        this.setToken(access, refresh)
      }),
    )
  }

  // getters

  get onMobileAppFinishRefreshingToken$(): Observable<TokenModel> {
    return window[MOBILE_FINISH_REFRESHING_TOKEN_OBSERVABLE].asObservable()
  }

  get onMobileAppChangedTheme$(): Observable<ThemeType> {
    return window[MOBILE_THEME_OBSERVABLE].asObservable()
  }

  get initialized$(): Observable<boolean> {
    return this._initialized.asObservable()
  }

  get initialized(): boolean {
    return this._initialized.getValue()
  }

  get lang(): LangType {
    return this._lang
  }

  get theme(): ThemeType {
    return this._theme
  }

  get token(): string {
    return this._token
  }

  get tokenExpireMoment(): Moment {
    return this._tokenExpireMoment
  }

  // setters

  setTheme(theme: ThemeType): void {
    this._theme = theme

    if (theme === 'dark') {
      document.documentElement.classList.add('dark')
    } else {
      document.documentElement.classList.remove('dark')
    }
  }

  setToken(access: string, refresh: string): void {
    this._token = access
    this._refresh = refresh
    this._tokenExpireMoment = retrieveTokenExpireMoment(access)
  }
}
