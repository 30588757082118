<div class="fixed top-0 max-w-xl w-full h-screen mx-auto left-[50%] -translate-x-[50%] z-0">
	<div class="absolute -left-28 -top-20 blur-[76px] w-[478px] h-[478px] bg-[#514EFF] rounded-full"></div>
	<div class="absolute -right-40 top-[50%] -translate-y-[50%] blur-[76px] w-[478px] h-[478px] bg-[#7C4EFF] rounded-full"></div>
	<div class="absolute -left-32 -bottom-12 blur-[76px] w-[478px] h-[478px] bg-[#3699FB] rounded-full"></div>
</div>

<div class="page">

	<div insets [paddingTop]="true" #header class="flex justify-center backdrop-blur-lg">

		<div class="flex justify-between w-full max-w-xl p-4">

			@if (isScrolled) {
				<svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M0 13.3C0 8.81958 0 6.57937 0.871948 4.86808C1.63893 3.36278 2.86278 2.13893 4.36808 1.37195C6.07937 0.5 8.31958 0.5 12.8 0.5H14.2C18.6804 0.5 20.9206 0.5 22.6319 1.37195C24.1372 2.13893 25.3611 3.36278 26.1281 4.86808C27 6.57937 27 8.81958 27 13.3V14.7C27 19.1804 27 21.4206 26.1281 23.1319C25.3611 24.6372 24.1372 25.8611 22.6319 26.6281C20.9206 27.5 18.6804 27.5 14.2 27.5H12.8C8.31958 27.5 6.07937 27.5 4.36808 26.6281C2.86278 25.8611 1.63893 24.6372 0.871948 23.1319C0 21.4206 0 19.1804 0 14.7V13.3Z"
						fill="white"
					/>
					<path
						d="M15.6723 22.5409C14.7774 22.5409 14.0733 22.4141 13.56 22.1606C13.0599 21.9205 12.7112 21.5536 12.5138 21.0599C12.3164 20.5663 12.2111 19.9525 12.1979 19.2187C10.5529 19.0186 9.30263 18.3915 8.4472 17.3375C7.59178 16.2702 7.16406 14.7292 7.16406 12.7146C7.16406 10.3264 7.70364 8.61192 8.78279 7.57126C9.86195 6.53059 11.4346 6.01025 13.5008 6.01025C15.567 6.01025 17.1396 6.53059 18.2188 7.57126C19.298 8.61192 19.8375 10.3264 19.8375 12.7146C19.8375 14.6892 19.423 16.2035 18.5939 17.2575C17.7779 18.3115 16.5869 18.9519 15.0208 19.1787C15.0208 19.6056 15.0932 19.8992 15.238 20.0593C15.3959 20.2327 15.6591 20.3194 16.0276 20.3194C16.4356 20.3194 16.8501 20.2661 17.2713 20.1593V22.3407C17.0738 22.3941 16.8106 22.4408 16.4816 22.4808C16.1658 22.5209 15.896 22.5409 15.6723 22.5409ZM13.5008 16.557C14.5668 16.557 15.3433 16.2502 15.8302 15.6364C16.3303 15.0094 16.5803 14.0354 16.5803 12.7146C16.5803 11.2736 16.3303 10.2596 15.8302 9.67261C15.3433 9.07222 14.5668 8.77203 13.5008 8.77203C12.4216 8.77203 11.632 9.07222 11.1319 9.67261C10.645 10.2596 10.4015 11.2736 10.4015 12.7146C10.4015 14.0354 10.645 15.0094 11.1319 15.6364C11.632 16.2502 12.4216 16.557 13.5008 16.557Z"
						fill="#312EC6"
					/>
				</svg>
			} @else {
				<svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M0 13.3C0 8.81958 0 6.57937 0.871948 4.86808C1.63893 3.36278 2.86278 2.13893 4.36808 1.37195C6.07937 0.5 8.31958 0.5 12.8 0.5H14.2C18.6804 0.5 20.9206 0.5 22.6319 1.37195C24.1372 2.13893 25.3611 3.36278 26.1281 4.86808C27 6.57937 27 8.81958 27 13.3V14.7C27 19.1804 27 21.4206 26.1281 23.1319C25.3611 24.6372 24.1372 25.8611 22.6319 26.6281C20.9206 27.5 18.6804 27.5 14.2 27.5H12.8C8.31958 27.5 6.07937 27.5 4.36808 26.6281C2.86278 25.8611 1.63893 24.6372 0.871948 23.1319C0 21.4206 0 19.1804 0 14.7V13.3Z"
						fill="#312EC6"
					/>
					<path
						d="M15.6723 22.5409C14.7774 22.5409 14.0733 22.4141 13.56 22.1606C13.0599 21.9205 12.7112 21.5536 12.5138 21.0599C12.3164 20.5663 12.2111 19.9525 12.1979 19.2187C10.5529 19.0186 9.30263 18.3915 8.4472 17.3375C7.59178 16.2702 7.16406 14.7292 7.16406 12.7146C7.16406 10.3264 7.70364 8.61192 8.78279 7.57126C9.86195 6.53059 11.4346 6.01025 13.5008 6.01025C15.567 6.01025 17.1396 6.53059 18.2188 7.57126C19.298 8.61192 19.8375 10.3264 19.8375 12.7146C19.8375 14.6892 19.423 16.2035 18.5939 17.2575C17.7779 18.3115 16.5869 18.9519 15.0208 19.1787C15.0208 19.6056 15.0932 19.8992 15.238 20.0593C15.3959 20.2327 15.6591 20.3194 16.0276 20.3194C16.4356 20.3194 16.8501 20.2661 17.2713 20.1593V22.3407C17.0738 22.3941 16.8106 22.4408 16.4816 22.4808C16.1658 22.5209 15.896 22.5409 15.6723 22.5409ZM13.5008 16.557C14.5668 16.557 15.3433 16.2502 15.8302 15.6364C16.3303 15.0094 16.5803 14.0354 16.5803 12.7146C16.5803 11.2736 16.3303 10.2596 15.8302 9.67261C15.3433 9.07222 14.5668 8.77203 13.5008 8.77203C12.4216 8.77203 11.632 9.07222 11.1319 9.67261C10.645 10.2596 10.4015 11.2736 10.4015 12.7146C10.4015 14.0354 10.645 15.0094 11.1319 15.6364C11.632 16.2502 12.4216 16.557 13.5008 16.557Z"
						fill="white"
					/>
				</svg>
			}

			<button quit class="flex gap-1 items-center justify-center text-center bg-[#EC2222] hover:bg-[#EC2222] text-white px-4 rounded text-[14px] transition-all">
				<mat-icon class="text-sm">logout</mat-icon>
				<span class="font-normal">Выйти</span>
			</button>

		</div>

	</div>

	<div class="page-body relative flex flex-col">

		<div class="p-4 pt-0 w-full max-w-xl z-10 mx-auto">
			<div class="flex flex-row gap-3 p-3 items-center bg-white bg-opacity-40 rounded-[10px]">
				<avatar [user]="profileService.user.student_profile"/>
				<div class="flex flex-col gap-2">
        <span class="text-[#05044B] font-semibold text-[13px]">
          {{ profileService.user.student_profile.first_name }} {{ profileService.user.student_profile.last_name }} {{ profileService.user.student_profile.middle_name }}
        </span>
					<span class="text-[#0F5733] text-xs">Вы</span>
				</div>
			</div>
		</div>

		<div insets [paddingBottom]="true" #content class="relative flex flex-col flex-1 rounded-t-2xl border-t border-gray-200 bg-white w-full max-w-xl z-10 mx-auto p-4 shadow-top">
			<div class="absolute left-0 right-0 bottom-0 top-0 overflow-hidden select-none z-0">
				@for (size of sizes; track size) {
					<div
						class="absolute left-[50%] top-[50%] transform -translate-x-1/2 -translate-y-1/2 border border-[rgba(49,46,198,0.05)] rounded-full pointer-events-none"
						[style.width.px]="size"
						[style.height.px]="size"
					></div>
				}
			</div>

			<div class="flex items-center justify-center text-center h-full text-[#0E0C51] text-base leading-6 z-10">
				Ожидание старта...
			</div>

		</div>

	</div>

</div>
