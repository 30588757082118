import {Injectable} from '@angular/core'
import {Dialog} from '@angular/cdk/dialog'
import {Router} from '@angular/router'
import {firstValueFrom} from 'rxjs'
import {Centrifuge, ConnectedContext, DisconnectedContext, JoinContext, LeaveContext, PublicationContext, SubscribedContext, Subscription, SubscriptionErrorContext} from 'centrifuge'

import {environment} from '@env'
import {QuizService} from '@quiz'
import {StudentRestrictedComponent} from '@features/student/waitlist/dialog/restricted.component'
import {ProfileService} from './profile.service'
import {rnNavigateBack} from '@core/utils/rn-webview'

@Injectable({
  providedIn: 'root'
})
export class CentrifugoService {

  private centrifuge: Centrifuge

  subscriptionMap: Record<string, Subscription> = {}

  constructor(
    private router: Router,
    private quizService: QuizService,
    private profileService: ProfileService,
    private dialog: Dialog,
  ) {
    this.centrifuge = new Centrifuge(environment.api_hs_quiz_centrifugo, {
      getToken: async () => {
        const {token} = await firstValueFrom(this.quizService.connect())
        return token
      },
    })
  }

  connect(
    onConnect: (ctx: ConnectedContext) => void = (ctx) => console.log('Connected to Centrifugo:', ctx),
    onDisconnect: (ctx: DisconnectedContext) => void = (ctx) => console.log('Disconnected from Centrifugo:', ctx),
  ): void {
    this.centrifuge.connect()

    this.centrifuge.on('connected', onConnect)
    this.centrifuge.on('disconnected', onDisconnect)
  }

  async subscribeQuiz(
    quiz: string,
    onPublication: (ctx: PublicationContext) => void,
    onSubscribed: (ctx: SubscribedContext) => void = (ctx) => console.log(`Subscribed to ${ctx.channel}`, ctx),
    onJoin: (ctx: JoinContext) => void = (ctx) => console.log(`Joined ${ctx.channel}:`, ctx.info.user),
    onLeave: (ctx: LeaveContext) => void = (ctx) => console.log(`Leaved ${ctx.channel}:`, ctx.info.user),
    onError: (err: SubscriptionErrorContext) => void = (err) => console.error(`Subscription error on ${err.channel}`, err),
  ): Promise<void> {
    const quizChannel = `quiz:${quiz}`
    const personalChannel = `personal$${this.profileService.user.user_id}`

    let quiz_token, personal_token

    if (!!this.profileService.user.staff_member_profile) {
      const token = await firstValueFrom(this.quizService.observe(quiz))
      quiz_token = token.quiz_token
      personal_token = token.personal_token
    } else {
      try {
        const token = await firstValueFrom(this.quizService.subscribe(quiz))
        quiz_token = token.quiz_token
        personal_token = token.personal_token
      } catch {
        this.dialog.open(StudentRestrictedComponent, {
          hasBackdrop: true,
          disableClose: true,
          data: {
            delay: false,
            onClose: rnNavigateBack,
          },
        })
      }
    }

    this.subscriptionMap[quizChannel] = this.centrifuge.newSubscription(quizChannel, {token: quiz_token})
    this.subscriptionMap[personalChannel] = this.centrifuge.newSubscription(personalChannel, {token: personal_token})

    this.subscriptionMap[quizChannel].on('publication', onPublication)
    this.subscriptionMap[personalChannel].on('publication', onPublication)

    this.subscriptionMap[quizChannel].on('subscribed', onSubscribed)
    this.subscriptionMap[personalChannel].on('subscribed', onSubscribed)
    this.subscriptionMap[quizChannel].on('error', onError)
    this.subscriptionMap[personalChannel].on('error', onError)

    this.subscriptionMap[quizChannel].subscribe()
    this.subscriptionMap[personalChannel].subscribe()

    this.subscriptionMap[quizChannel].on('join', onJoin)
    this.subscriptionMap[quizChannel].on('leave', onLeave)
  }

  async presenceQuiz(guid: string): Promise<number[]> {
    if (this.profileService.isStudent) return []
    if (!this.subscriptionMap[`quiz:${guid}`]) return []
    const res = await this.subscriptionMap[`quiz:${guid}`].presence()
    return Object.values(res.clients).map(client => Number(client.user))
  }

  unsubscribeQuiz(guid: string): void {
    const channel = `quiz:${guid}`
    if (!this.subscriptionMap[channel]) return
    this.subscriptionMap[channel].unsubscribe()
  }

  unsubscribePersonal(): void {
    const channel = `personal$${this.profileService.user.user_id}`
    if (!this.subscriptionMap[channel]) return
    this.subscriptionMap[channel].unsubscribe()
  }

  disconnect(): void {
    this.centrifuge.disconnect()
  }
}
