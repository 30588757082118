export function solveQuadratic(a: number = 4, b: number = 72, _c: number = 24, height: number): number {
  const c = _c - height
  const discriminant = Math.pow(b, 2) - 4 * a * c

  if (discriminant < 0) {
    return null
  } else if (discriminant === 0) {
    const x = -b / (2 * a)
    return x
  } else {
    const sqrtDiscriminant = Math.sqrt(discriminant)
    const x1 = (-b + sqrtDiscriminant) / (2 * a)
    const x2 = (-b - sqrtDiscriminant) / (2 * a)
    return Math.max(Math.ceil(x1), Math.ceil(x2))
  }
}
