import {Routes} from '@angular/router'
import {QuestionComponent} from "@features/student/question/question.component"
import {LeaderboardComponent} from "@features/leaderboard/leaderboard.component"
import {StartComponent} from "@features/teacher/start/start.component"
import {StatisticsComponent} from "@features/teacher/statistics/statistics.component"
import {RatingComponent} from "@features/teacher/statistics/rating/rating.component"
import {StatisticQuestionListComponent} from "@features/teacher/statistics/questions/questions.component"
import {WaitlistComponent} from "@features/student/waitlist/waitlist.component"
import {QuizComponent} from "@features/teacher/quiz/quiz.component"
import {RoleGuard} from "@core/guard/role.guard"
import {AuthGuard} from "@core/guard/auth.guard"
import {StartTimerComponent} from "@features/student/start/start.component"
import {ActivateComponent} from "@features/teacher/activate/activate.component"
import {ResultComponent} from "@features/student/result/result.component"
import {ResultDetailsComponent} from "@features/student/result/details/details.component"
import {QuestionExplanationComponent} from "@features/student/question/explanation/explanation.component"
import {MainComponent} from "@features/main/main.component"

export const routes: Routes = [
  {
    path: ':guid',
    component: MainComponent,
    canActivate: [AuthGuard],
    children: [
      // TEACHER
      {
        path: '',
        component: QuizComponent,
        canActivate: [RoleGuard],
        data: {
          isRoot: true,
          isTeacher: true,
          isStudent: false,
          redirect: 'test'
        },
      },
      {
        path: 'activate',
        canActivate: [RoleGuard],
        component: ActivateComponent,
        data: {
          isTeacher: true,
          isStudent: false,
          redirect: 'test'
        },
      },
      {
        path: 'start',
        canActivate: [RoleGuard],
        component: StartComponent,
        data: {
          isTeacher: true,
          isStudent: false,
          redirect: 'test'
        },
      },
      {
        path: 'statistics',
        component: StatisticsComponent,
        children: [
          {path: '', redirectTo: 'ratings', pathMatch: 'prefix'},
          {path: 'ratings', component: RatingComponent},
          {path: 'questions', component: StatisticQuestionListComponent}
        ],
        canActivate: [RoleGuard],
        data: {
          isTeacher: true,
          isStudent: false,
          redirect: 'test'
        },
      },

      // STUDENTS
      {
        path: 'waitlist',
        component: WaitlistComponent,
        canActivate: [RoleGuard],
        data: {
          isTeacher: false,
          isStudent: true
        }
      },
      {
        path: 'results',
        component: ResultComponent,
        canActivate: [RoleGuard],
        data: {
          isTeacher: false,
          isStudent: true,
        }
      },
      {
        path: 'results/details',
        component: ResultDetailsComponent,
        canActivate: [RoleGuard],
        data: {
          isTeacher: false,
          isStudent: true,
        }
      },
      {
        path: 'start-timer',
        component: StartTimerComponent
      },
      {
        path: 'test',
        component: QuestionComponent,
        canActivate: [RoleGuard],
        data: {
          isTeacher: false,
          isStudent: true,
        }
      },
      {
        path: 'test/explanation',
        component: QuestionExplanationComponent,
        canActivate: [RoleGuard],
        data: {
          isTeacher: false,
          isStudent: true,
        }
      },
      {
        path: 'test/:questionId',
        component: QuestionComponent,
        canActivate: [RoleGuard],
        data: {
          isTeacher: false,
          isStudent: true,
        }
      },
      {
        path: 'leaderboard',
        component: LeaderboardComponent,
      }
    ]
  }
]
