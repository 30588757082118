import {Component} from '@angular/core'
import {opacityAnimation} from "@core/animations/opacity"
import {TimerComponent} from "@core/timer/timer.component"
import {MathjaxHtmlDirective} from "@shared/directives/mathjax-html.directive"
import {CommonModule} from "@angular/common"
import {IconComponent} from "@shared/components/icon.component"
import {slideInAnimation} from "@core/animations/slidein"
import {AppState} from "../../../../app.state"
import {QuestionCountdownComponent} from "@features/student/countdown/countdown.component"
import {RouterLink} from "@angular/router"
import {InsetsDirective} from "@shared/directives/insets.directive"

@Component({
  standalone: true,
  selector: 'question-explanation',
  imports: [CommonModule, MathjaxHtmlDirective, TimerComponent, IconComponent, QuestionCountdownComponent, RouterLink, InsetsDirective],
  animations: [opacityAnimation, slideInAnimation],
  templateUrl: './explanation.component.html'
})
export class QuestionExplanationComponent {
  sizes = []
  selected: number
  answered: boolean = false

  constructor(public state: AppState) { }
}
