<question-countdown>
  @if (state.question) {
    @if (answered) {
	    <div insets [paddingBottom]="true" [@routeAnimations]="state.question.id" class="page-body w-full max-w-xl mx-auto relative">
		    <div class="flex flex-col gap-5 p-4">
	        @if (state.correctAnswer) {
	          @if (state.correctAnswer.id === state.selectedAnswer?.id) {
	            <div @opacityAnimation class="flex items-center bg-[#4CCE17] rounded-xl p-4 w-full max-w-xl overflow-hidden">
	              <div class="w-6 h-6 rounded-full bg-white outline-8 outline-red-300">
	                <mat-icon class="font-extrabold text-2xl leading-6 text-[#4CCE17]">check</mat-icon>
	              </div>
	              <span class="flex-1 text-center text-white font-medium">Верно</span>
	            </div>
	          } @else {
	            <div @opacityAnimation class="flex items-center bg-[#EC2222] rounded-xl p-4 w-full max-w-xl overflow-hidden">
	              <div class="w-6 h-6 rounded-full bg-white">
	                <mat-icon class="font-extrabold text-2xl leading-6 text-[#EC2222]">close_small</mat-icon>
	              </div>
	              <span class="flex-1 text-center text-white font-medium">Неверно:(</span>
	            </div>
	          }
	          @if(state.question.explanation) {
	            <div @opacityAnimation class="bg-[#F0F2FD] flex flex-col gap-4 justify-between items-start p-4 rounded-xl w-full max-w-xl">
	              <div class="p-1 w-7 h-7 rounded-full bg-white shadow-lg">
	                <mat-icon class="text-[20px] leading-5 text-primary-700">lightbulb</mat-icon>
	              </div>
	              <div [innerMathjaxHTML]="state.question.explanation"></div>
	            </div>
	          }
	        } @else {
	          <div class="flex items-center justify-center h-full">
	            Ожидание всех ответов...
	          </div>
	        }
	      </div>
      </div>
    } @else {
	    <div insets [paddingBottom]="true" [@routeAnimations]="state.question.id" class="page-body w-full max-w-xl mx-auto relative">
		    <div class="flex flex-col gap-5 p-4">
	        <div #question class="relative overflow-hidden w-full max-w-xl">
	          <div class="bg-[#807DFF] flex flex-col justify-between items-start p-4 rounded-lg text-white min-h-[280px]">
	            <div class="bg-[rgba(0,0,0,0.12)] px-[6px] rounded-full text-white">
	              {{state.question.index + 1}} / {{state.questions.length}}
	            </div>
	            <div [innerMathjaxHTML]="state.question.body"></div>
	          </div>
	          @for (size of [72, 128, 190, 260, 332, 406, 482, 560]; track size){
	            <div
	              class="absolute left-[50%] top-[50%] transform -translate-x-1/2 -translate-y-1/2 border border-[rgb(255,255,255,0.1)] rounded-full pointer-events-none"
	              [style.width.px]="size"
	              [style.height.px]="size"
	            ></div>
	          }
	        </div>
	        @for (answer of state.question.answers; track answer.id){
	          <div
	            @opacityAnimation
	            class="border rounded-[13px] max-w-xl w-full"
	            [class.border-transparent]="selected !== answer.id"
	            [class.border-primary-700]="selected === answer.id"
	          >
	            <div
	              class="px-3 py-4 bg-primary-50 border text-primary-400 rounded-xl cursor-pointer w-full"
	              [class.border-primary-100]="selected !== answer.id"
	              [class.border-primary-700]="selected === answer.id"
	              [innerMathjaxHTML]="answer.body"
	              (click)="onSelect(answer)"
	            ></div>
	          </div>
	        }

	      </div>
      </div>
	    <div insets [paddingBottom]="true" [@opacityAnimation]="state.question" class="flex justify-center border-b border-[#F8F8F8] shadow-top bg-white">
		    <div class="flex justify-between w-full max-w-xl p-4">
          <button
            [disabled]="!selected || answered"
            (click)="answerQuestion(state.question.id)"
            class="w-full flex gap-1 items-center justify-center text-center bg-primary-700 hover:bg-primary-700 disabled:opacity-70 text-white py-3 px-4 rounded-xl text-base transition-all max-w-xl"
          >
            Ответить
          </button>
	      </div>
      </div>
    }
  }
</question-countdown>
