import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core'
import {ProfileService} from "@core/services/profile.service"
import {IconComponent} from "@shared/components/icon.component"
import {InsetsDirective} from "@shared/directives/insets.directive"
import {AppState} from "../../../app.state"
import {RouterLink} from "@angular/router"
import {opacityAnimation} from "@core/animations/opacity"
import {AvatarComponent} from "@shared/components/avatar.component"
import {QuizPlayer} from "@quiz"
import {QuitDirective} from '@shared/directives/quit.directive'

@Component({
  standalone: true,
  imports: [InsetsDirective, IconComponent, AvatarComponent, RouterLink, QuitDirective],
  animations: [opacityAnimation],
  templateUrl: './result.component.html'
})
export class ResultComponent implements AfterViewInit {

  @ViewChild('header') headerElement!: ElementRef

  position: QuizPlayer & {place: number}

  constructor(
    public profileService: ProfileService,
    public state: AppState
  ) {
  }

  ngAfterViewInit(): void {
    this.state.players$.subscribe(() => {
      setTimeout(() => this.position = this.state.leaderboard.map((p, i) => ({...p, place: i + 1})).find(p => p.user_id === this.profileService.user.user_id), 50)
    })
  }

  get correct_cnt(): number {
    return this.state.questions.filter(q => q.correct).length
  }
  get wrong_cnt(): number {
    return this.state.questions.filter(q => !q.correct).length
  }
}
