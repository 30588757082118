import {Injectable} from '@angular/core'
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router'
import {filter, takeWhile} from 'rxjs'

import {rnLog} from '@core/utils/rn-webview'
import {AppService} from '@core/services/app.service'

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private appService: AppService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    if (this.appService.initialized) {
      rnLog('[AppGuard] App is already initialized, navigating page...')
      return true
    }

    this.appService.startAppInit(state)

    return this.appService.initialized$
      .pipe(
        takeWhile(init => !init, true),
        filter(init => init),
      )
  }
}
