import {Directive, ElementRef, HostListener, Input} from '@angular/core'

@Directive({
  standalone: true,
  selector: 'img[fallback]',
})
export class ImageFallbackDirective {
  @Input() fallback: string

  constructor(private ref: ElementRef) { }

  @HostListener('error')
  loadFallback() {
    const el: HTMLImageElement = <HTMLImageElement>this.ref.nativeElement
    el.src = this.fallback || ''
  }
}
