import {Component} from '@angular/core'
import {RouterLink} from '@angular/router'

import {rnNavigateBack} from '@core/utils/rn-webview'
import {IconComponent} from '@shared/components/icon.component'
import {MathjaxHtmlDirective} from '@shared/directives/mathjax-html.directive'
import {DurationPipe} from '@shared/pipes/duration.pipe'
import {InsetsDirective} from '@shared/directives/insets.directive'

import {QuizQuestionComponent} from './question/question.component'
import {AppState} from '../../../app.state'

@Component({
  standalone: true,
  templateUrl: './quiz.component.html',
  imports: [RouterLink, IconComponent, MathjaxHtmlDirective, DurationPipe, QuizQuestionComponent, InsetsDirective],
})
export class QuizComponent {

  constructor(
    public state: AppState,
  ) {
  }

  quit() {
    rnNavigateBack()
  }
}
