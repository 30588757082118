import {Directive, ElementRef, Input, OnInit} from '@angular/core'

import {getSafeAreaInsets} from "@core/utils/safe-area"
import {MOBILE_BOTTOM_TAB_HEIGHT} from "@core/constants/constants"

type Spacing = boolean | number

const isPx = (spacing: Spacing) => typeof spacing === 'number' && spacing > 0

@Directive({
  standalone: true,
  selector: '[insets]',
})
export class InsetsDirective implements OnInit {

  @Input() paddingTop: Spacing
  @Input() paddingBottom: Spacing

  @Input() marginTop: Spacing
  @Input() marginBottom: Spacing

  @Input() hasBottomTabBar: boolean

  constructor(
    private elementRef: ElementRef,
  ) {
    this.elementRef.nativeElement.style.display = 'none'
  }

  ngOnInit(): void {
    this.elementRef.nativeElement.style.display = null

    if (this.paddingTop) {
      let {top} = getSafeAreaInsets()

      if (isPx(this.paddingTop)) {
        top += this.paddingTop
      }

      this.elementRef.nativeElement.style.paddingTop = `${top}px`
    }

    if (this.paddingBottom) {
      let {bottom} = getSafeAreaInsets()

      if (isPx(this.paddingBottom)) {
        bottom += this.paddingBottom
      }

      if (this.hasBottomTabBar) {
        bottom += MOBILE_BOTTOM_TAB_HEIGHT
      }

      this.elementRef.nativeElement.style.paddingBottom = `${bottom}px`
    }

    if (this.marginTop) {
      let {top} = getSafeAreaInsets()

      if (isPx(this.marginTop)) {
        top += this.marginTop
      }

      const px = `${top}px`
      this.elementRef.nativeElement.style.marginTop = px

      // if (this.marginBottom) {
      //   this.elementRef.nativeElement.style.setProperty('--rn-inset', px);
      //   this.elementRef.nativeElement.classList.add('rn-inset', 'rn-inset-top')
      // }
    }

    if (this.marginBottom) {
      let {bottom} = getSafeAreaInsets()

      if (isPx(this.marginBottom)) {
        bottom += this.marginBottom
      }

      if (this.hasBottomTabBar) {
        bottom += MOBILE_BOTTOM_TAB_HEIGHT
      }

      const px = `${bottom}px`
      this.elementRef.nativeElement.style.marginBottom = px

      // if (this.marginTop) {
      //   this.elementRef.nativeElement.style.setProperty('--rn-inset', px);
      //   this.elementRef.nativeElement.classList.add('rn-inset', 'rn-inset-bottom')
      // }
    }
  }
}
