import {Component} from '@angular/core'
import {AppState} from '../../../../app.state'
import {AvatarComponent} from '@shared/components/avatar.component'

@Component({
  imports: [AvatarComponent],
  standalone: true,
  templateUrl: './rating.component.html',
})
export class RatingComponent {
  constructor(public state: AppState) {
  }
}
