import {Injectable} from '@angular/core'
import {finalize, combineLatest, Observable, of, switchMap, tap} from 'rxjs'
import {Quiz, QuizTest} from "./models/quiz.model"
import {TestQuestion} from "./models/test.model"
import {QuizService} from "./services/quiz.service"

@Injectable()
export class QuizState {
  private _quiz: Quiz
  private _test: QuizTest
  private _questions: TestQuestion[] = []

  private _loading: boolean = false

  get quiz(): Quiz {
    return this._quiz
  }

  get test(): QuizTest {
    return this._test
  }

  get questions(): TestQuestion[] {
    return this._questions
  }

  get loading(): boolean {
    return this._loading
  }

  setLoading(loading: boolean): void {
    this._loading = loading
  }

  constructor(private quizService: QuizService) { }

  getQuiz(quizId: string): Observable<Quiz> {
    this.setLoading(true)

    return this.quizService.getQuiz(quizId).pipe(
      tap(quiz => this._quiz = quiz),
      finalize(() => this.setLoading(false)),
    )
  }

  updateQuiz(payload: any): Observable<Quiz> {
    this.setLoading(true)

    return this.quizService.updateQuiz(this._quiz.guid, payload).pipe(
      tap(quiz => this._quiz = quiz),
      finalize(() => this.setLoading(false)),
    )
  }

  publishQuiz(): Observable<Quiz> {
    this.setLoading(true)

    return this.quizService.publishQuiz(this.quiz.guid).pipe(
      switchMap(() => this.getQuiz(this.quiz.guid)),
      finalize(() => this.setLoading(false)),
    )
  }

  listQuestion(): Observable<TestQuestion[]> {
    this.setLoading(true)
    return this.quizService.listQuestions(this._quiz.guid).pipe(
      tap(questions => this._questions = questions),
      finalize(() => this.setLoading(false)),
    )
  }

  saveQuestions(prevQuestions: any[], currQuestions: any[]): Observable<TestQuestion[]> {
    const prevAnswers = prevQuestions.reduce((prev, curr) => ([
      ...prev,
      ...curr.answers.filter(ans => !!ans.id).map(ans => ({...ans, question: curr.id}))
    ]), [])

    const payload: any[] = currQuestions.map((payload, questionIdx) => ({
      ...payload,
      order: questionIdx + 1,
      answers: payload.answers.map((answer, answerIdx) => ({
        ...answer,
        order: answerIdx + 1
      }))
    }))

    const questionsCreate = payload.filter(payload => !!!payload.id)
    const questionsUpdate = payload.filter(payload => !!payload.id && JSON.stringify(payload) !== JSON.stringify(prevQuestions.find(obj => obj.id === payload.id)))

    const questionIds = payload.filter(payload => !!payload.id).map(payload => payload.id)
    const answerIds = payload.reduce((prev, curr) => ([...prev, ...curr.answers.filter(ans => !!ans.id).map(ans => ans.id)]), [])

    const questionsDelete: number[] = prevQuestions.filter(payload => !questionIds.includes(payload.id)).map((obj: any) => obj.id)
    const deleteAnswers: [number, number][] = prevAnswers
      .filter(answer => !answerIds.includes(+answer.id) && !questionsDelete.includes(+answer.question))
      .map(ans => ([ans.question, ans.id]))

    if (questionsDelete.length + deleteAnswers.length + questionsCreate.length + questionsUpdate.length === 0) {
      return of(this._questions)
    }

    return combineLatest([
      ...questionsDelete.map(id => this.quizService.deleteQuestion(this._quiz.guid, id)),
      ...deleteAnswers.map(([questionId, answerId]) => this.quizService.deleteQuestionAnswer(this._quiz.guid, questionId, answerId)),
      ...questionsCreate.map((payload) => this.quizService.createQuestion(this._quiz.guid, payload)),
      ...questionsUpdate.map((payload) => this.quizService.updateQuestion(this._quiz.guid, payload.id, payload)),
    ]).pipe(
      switchMap(() => this.listQuestion()),
      finalize(() => this.setLoading(false))
    )
  }

  clearState(): void {
    this._quiz = null
    this._test = null
    this._questions = []
    this.setLoading(false)
  }
}
