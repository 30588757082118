import {Injectable} from '@angular/core'
import {CentrifugoService} from '@core/services/centrifugo.service'
import {Quiz, QuizMode, QuizPlayer, QuizPlayerStatus, QuizService, QuizStatus, QuizTest, TestQuestion, TestQuestionAnswer, TestQuestionPlayerAnswerStatistic} from '@quiz'
import {BehaviorSubject, combineLatest, firstValueFrom, interval, mergeMap, Observable, of, Subject, switchMap, tap} from 'rxjs'
import {TestStatistic} from '../../projects/quiz/src/public-api'
import {Router} from '@angular/router'
import {ProfileService} from '@core/services/profile.service'
import moment from 'moment'
import {Dialog} from '@angular/cdk/dialog'
import {StudentRestrictedComponent} from '@features/student/waitlist/dialog/restricted.component'
import {rnNavigateBack} from '@core/utils/rn-webview'
import {sendAmplitudeEvent} from '@core/utils/send-amplitude-event'

export interface TestQuestionIndexed extends TestQuestion {
  index: number
}

@Injectable()
export class AppState {
  private _players: QuizPlayer[] = []
  private _leaderboard: QuizPlayer[] = []
  private _statistics: TestStatistic = undefined
  private _statisticsAnswers: Record<number, Record<number, boolean>> = {}
  private _statisticsPoints: Record<number, number> = {}
  private _questionStatistics: Record<number, number> = {}
  private _questions: TestQuestion[] = []
  private _questionMap: Record<number, TestQuestionIndexed> = {}
  private _question: TestQuestionIndexed
  private _selectedAnswer: TestQuestionAnswer
  private _correctAnswer: TestQuestionAnswer

  question$ = new Subject<TestQuestion>
  quiz$ = new BehaviorSubject<Quiz>(undefined)
  test$ = new BehaviorSubject<QuizTest>(undefined)
  players$ = new BehaviorSubject<QuizPlayer[]>([])
  onlineUsers$ = new BehaviorSubject<number[]>([])

  public get quiz(): Quiz {
    return this.quiz$.value
  }

  public get test(): QuizTest {
    return this.test$.value
  }

  public get question(): TestQuestionIndexed {
    return this._question
  }

  public get selectedAnswer(): TestQuestionAnswer {
    return this._selectedAnswer
  }

  public get correctAnswer(): TestQuestionAnswer {
    return this._correctAnswer
  }

  public get questions(): TestQuestion[] {
    return this._questions
  }

  public get players(): QuizPlayer[] {
    return this._players
  }

  public get joinedPlayers(): QuizPlayer[] {
    return this._players.filter(p => p.status === QuizPlayerStatus.JOINED)
  }

  public get leaderboard(): QuizPlayer[] {
    return this._leaderboard
  }

  public get statistics(): TestStatistic {
    return this._statistics
  }

  public get statisticsAnswers(): Record<number, Record<number, boolean>> {
    return this._statisticsAnswers
  }

  public get statisticsPoints(): Record<number, number> {
    return this._statisticsPoints
  }

  public get questionStatistics(): Record<number, number> {
    return this._questionStatistics
  }

  constructor(
    private router: Router,
    private quizService: QuizService,
    private profileService: ProfileService,
    private centrifugoService: CentrifugoService,
    private dialog: Dialog,
  ) {
  }

  getQuiz(guid: string): Observable<any> {
    if (!guid) return of()

    const onPublication = async (ctx) => {
      const data = ctx.data

      if (!this.profileService.user) return
      if (!data) return
      if (!data.action) return

      if (this.profileService.isTeacher) {
        if (data.action === 'PLAYER_ANSWER_UPDATE') {
          await firstValueFrom(this.getStatistics(this.quiz.guid))
        }
        if (data.action === 'PLAYER_CONNECTED') {
          setTimeout(async () => {
            const ids = await this.centrifugoService.presenceQuiz(guid)
            this.onlineUsers$.next(ids)
          }, 500)
        }
      }

      if (this.profileService.isStudent) {
        if (data.action === 'QUIZ_START') {
          this.router.navigate([`/${this.quiz.guid}/start-timer`], {replaceUrl: true})
        }

        if (data.action === 'PLAYER_KICKED') {
          this.centrifugoService.unsubscribeQuiz(this.quiz.guid)

          this.dialog.open(StudentRestrictedComponent, {
            hasBackdrop: true,
            disableClose: true,
            data: {
              delay: false,
              onClose: rnNavigateBack,
            },
          })
        }
      }

      if (data.action === 'QUIZ_UPDATE') {
        this.test$.next(data.data as QuizTest)
        this._correctAnswer = null
        this._selectedAnswer = null
        const questionId = data.data.current_question_id
        this._question = this._questionMap[+questionId]
        this.question$.next(this._question)
      }

      if (data.action === 'QUIZ_QUESTION_ANSWER') {
        const questionId = data.data.question_id
        const answerId = data.data.correct_answer_id
        this._question = this._questionMap[+questionId]
        this._correctAnswer = this._question?.answers.find(a => a.id === +answerId)
      }

      if (data.action === 'QUIZ_FINISH') {
        if (this.profileService.isStudent) this.sendAmplitudeEvent('quiz_pupil_completed')

        this.quizService.getQuiz(this.quiz.guid).pipe(tap(quiz => this.quiz$.next(quiz)))
        this.router.navigate([`/${this.quiz.guid}/leaderboard`], {replaceUrl: true})
      }
    }

    interval(5000)
      .pipe(mergeMap(() => this.centrifugoService.presenceQuiz(guid)))
      .subscribe(users => this.onlineUsers$.next(users))

    return this.quizService.getQuiz(guid).pipe(
      switchMap((quiz) => {
        this.quiz$.next(quiz)

        return combineLatest([
          this.getTest(guid),
          this.listQuestions(guid),
          this.listPlayers(guid),
        ]).pipe(tap(async ([test, questions]) => {
          this._question = this._questionMap[+this.test.current_question_id]
          this.question$.next(this._question)
          if ([QuizStatus.LIVE, QuizStatus.READY].includes(this.quiz.status)) {
            await this.centrifugoService.subscribeQuiz(guid, onPublication)
              .then(async () => {
                const ids = await this.centrifugoService.presenceQuiz(guid)
                this.onlineUsers$.next(ids)
              })
          }
        }))
      }),
    )
  }

  getTest(guid: string): Observable<QuizTest> {
    return this.quizService.getTest(guid).pipe(tap((test) => {
      this.test$.next(test)
      if (this._questionMap) {
        this._question = this._questionMap[+test.current_question_id]
        this.question$.next(this._question)
      }
    }))
  }

  listPlayers(guid: string): Observable<QuizPlayer[]> {
    return this.quizService.listPlayer(guid).pipe(
      tap(players => {
        this._players = players
        this.players$.next(players)
        this._leaderboard = players
          .filter(p => p.status === QuizPlayerStatus.JOINED)
          .sort((a: QuizPlayer, b: QuizPlayer) => b.points - a.points || moment.duration(a.time_spend, 'milliseconds').milliseconds() - moment.duration(b.time_spend, 'milliseconds').milliseconds())
      }),
    )
  }

  listQuestions(guid: string): Observable<TestQuestion[]> {
    return this.quizService.listQuestions(guid).pipe(
      tap(questions => {
        this._questions = questions
        this._questionMap = Object.fromEntries(questions.map((obj, index) => [obj.id, {...obj, index}]))
      }),
    )
  }

  kickPlayer(player: QuizPlayer): Observable<QuizPlayer[]> {
    return this.quizService.kickPlayer(this.quiz.guid, player.id).pipe(
      switchMap(() => this.quizService.listPlayer(this.quiz.guid)),
      tap(players => {
        this._players = players
        this.players$.next(players)
      }),
    )
  }

  restorePlayer(player: QuizPlayer) {
    return this.quizService.restorePlayer(this.quiz.guid, player.id).pipe(
      switchMap(() => this.quizService.listPlayer(this.quiz.guid)),
      tap(players => {
        this._players = players
        this.players$.next(players)
      }),
    )
  }

  activateQuiz(mode: QuizMode, payload: any): Observable<Quiz> {
    this.sendAmplitudeEvent('quiz_sending_invitation', {mode: 'synch'})

    return this.quizService.updateQuiz(this.quiz.guid, {mode}).pipe(switchMap(() => {
      return this.quizService.activateQuiz(this.quiz.guid, payload).pipe(tap(quiz => {
        this.quiz$.next(quiz)
      }))
    }))
  }

  startQuiz(mode: QuizMode): Observable<Quiz> {
    const allPlayersCount = this.players.length
    const onlinePlayersCount = this.onlineUsers$.getValue().length
    const pupilsStat = Math.round((onlinePlayersCount / allPlayersCount) * 100)

    this.sendAmplitudeEvent('quiz_start_selected', {pupils_stat: pupilsStat})

    return this.quizService.updateQuiz(this.quiz.guid, {mode}).pipe(switchMap(() => {
      return this.quizService.startQuiz(this.quiz.guid).pipe(tap(async quiz => {
        this.quiz$.next(quiz)
        await firstValueFrom(this.getTest(quiz.guid))
      }))
    }))
  }

  getStatistics(guid: string, params?: any): Observable<[TestStatistic, TestQuestionPlayerAnswerStatistic[]]> {
    return combineLatest([
      this.quizService.getTestStatistic(guid, params),
      this.quizService.listTestAnswerStatistic(guid, params),
    ]).pipe(
      tap(([statictics, answers]) => {
        this._statistics = {
          correct: answers.filter(a => a.correct).length,
          wrong: answers.filter(a => !a.correct).length,
          total: statictics.total,
        }
        this._statisticsAnswers = answers.reduce((prev, curr) => ({
          ...prev,
          [curr.player_id]: curr.player_id in prev ? {...prev[curr.player_id], [curr.question_id]: curr.correct} : {[curr.question_id]: curr.correct},
        }), {})
        this._statisticsPoints = answers.reduce((prev, curr) => ({
          ...prev,
          [curr.player_id]: curr.player_id in prev ? prev[curr.player_id] + curr.points : curr.points,
        }), {})

        this._questionStatistics = Object.fromEntries(
          Object.entries(
            answers.reduce((prev, curr) => ({
              ...prev,
              [curr.question_id]: curr.question_id in prev ? [...prev[curr.question_id], +curr.correct] : [+curr.correct],
            }), {}),
          ).map(
            ([key, list]: [string, number[]]) => [+key, +Math.round(100 * list.reduce((p, c) => p + c, 0) / list.length)],
          ),
        )
      }),
    )
  }

  // player
  playerStartQuiz(guid: string): Observable<undefined> {
    return this.quizService.playerStartQuiz(guid)
  }

  answerQuestion(questionId: number, answerId: number, started_at: Date, finished_at: Date): Observable<TestQuestionAnswer> {
    const payload = {
      answer_id: answerId,
      started_at: started_at.toISOString(),
      finished_at: finished_at.toISOString(),
    }
    this._selectedAnswer = this.question.answers.find(a => a.id === answerId)
    return this.quizService.answerQuestion(this.quiz.guid, questionId, payload).pipe(tap(async () => {
      if (this.quiz.mode === QuizMode.INDIVIDUAL) setTimeout(async () => await firstValueFrom(this.getTest(this.quiz.guid)), 5000)
    }))
  }

  sendAmplitudeEvent(event: string, params?: any) {
    sendAmplitudeEvent(event, {
      ...params,
      created_by: this.profileService.user.user_id,
      lesson_id: this.quiz.meta.lesson_id,
      school: this.quiz.meta.school_id,
      quiz_id: this.quiz.guid,
    })
  }
}
