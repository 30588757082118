import {animate, style, transition, trigger} from "@angular/animations"

export const slideInAnimation = trigger('routeAnimations', [
  transition(':enter', [
    style({position: 'absolute', opacity: 0.5, left: "100%"}),
    animate('300ms', style({position: 'absolute', opacity: 1, left: 0})),
  ]),
  transition(':leave', [
    style({position: 'absolute', opacity: 1, left: 0}),
    animate('300ms', style({position: 'absolute', opacity: 0.5, left: "-100%"}))
  ])
])
