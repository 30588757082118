<div class="flex flex-col gap-3 p-4 bg-white items-center rounded-xl">

  <div class="p-2 rounded-md bg-red-500 bg-opacity-10">
    <mat-icon class="text-red-500 font-bold text-2xl leading-6">priority_high</mat-icon>
  </div>

  <div class="text-base text-center">
    Вы не допущены на квиз
  </div>

  <button (click)="close()" class="w-full flex gap-1 items-center justify-center text-center bg-primary-700 hover:bg-primary-700 disabled:opacity-70 text-white py-3 px-4 rounded-lg text-base transition-all max-w-xl">
    Вернуться
  </button>

</div>
