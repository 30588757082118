import {Component, Input} from '@angular/core'
import {CommonModule} from '@angular/common'

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'mat-icon',
  styles: ':host {display: flex; justify-content: center; align-items: center;}',
  template: `<span class="material-symbols-rounded {{class}}" [style]="style"><ng-content></ng-content></span>`
})
export class IconComponent {
  @Input() icon: string
  @Input() class: string = ''
  @Input() style: string = ''
}
