import {interval, Subscription} from 'rxjs'

export abstract class Countdown {

  countdown: number
  time: number

  private subscription!: Subscription

  startCountdown(): void {
    this.subscription = interval(1000).subscribe(() => {
      this.countdown = this.countdown < 1 ? 0 : this.countdown - 1
    })
  }

  stopCountdown(): void {
    if (this.subscription) this.subscription.unsubscribe()
  }

  abstract initCountdown(): void

  abstract onFinishCountdown(): void
}

