import {Component, Input, OnInit} from '@angular/core'
import {QuizService} from "../services/quiz.service"
import {firstValueFrom} from "rxjs"

@Component({
  standalone: true,
  selector: 'lib-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.css']
})
export class QuizComponent implements OnInit {

  @Input() guid: string
  quiz: any

  constructor(private quizService: QuizService) { }

  async ngOnInit(): Promise<void> {
    this.quiz = firstValueFrom(this.quizService.getQuiz(this.guid))
  }
}
