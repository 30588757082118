export type RnWebviewEventType =
  'on_load' |
  'on_navigate' |
  'on_navigate_mobile' |
  'on_refresh_token' |
  'refresh_token' |
  'log' |
  'alert' |
  'share' |
  'check_contacts_permission' |
  'update_contacts' |
  'on_navigate_back' |
  'open_external_link' |
  'request_contacts';

interface RnWebviewPayloadModel {
  event: RnWebviewEventType;
  data?: any;
}

export const rnPostMessage = (payload: RnWebviewPayloadModel): void => {
  window['ReactNativeWebView']?.postMessage(JSON.stringify(payload))
}

export const rnOnLoad = (): void => {
  rnPostMessage({event: 'on_load'})
}

export const rnNavigate = (data: any): void => {
  rnPostMessage({event: 'on_navigate', data})
}

export const rnLog = (data: any): void => {
  rnPostMessage({event: 'log', data})
}

export const rnAlert = (title: string, message: string): void => {
  rnPostMessage({
    event: 'alert',
    data: {title, message},
  })
}

export const rnShare = (url: string): void => {
  rnPostMessage({
    event: 'share',
    data: {url},
  })
}

export const rnCheckContactsPermission = (): void => {
  rnPostMessage({event: 'check_contacts_permission'})
}

export const rnRequestContacts = (): void => {
  rnPostMessage({event: 'request_contacts'})
}

export const rnUpdateContacts = (): void => {
  rnPostMessage({event: 'update_contacts'})
}

export const rnNavigateBack = (): void => {
  rnPostMessage({event: 'on_navigate_back'})
}

export const rnOpenExternalLink = (): void => {
  rnPostMessage({event: 'open_external_link'})
}
