import {Component, Input} from '@angular/core'
import {CommonModule} from '@angular/common'

import {Profile} from '@core/schemas/user.model'
import {ImageFallbackDirective} from '@shared/directives/img-fallback.directive'

@Component({
  standalone: true,
  imports: [CommonModule, ImageFallbackDirective],
  selector: 'avatar',
  styles: ':host {}',
  template: `
		<img
			class="border-2 border-[#CBE1FF] rounded-full {{class}}"
			[src]="user?.avatar"
			[fallback]="fallback"
			[style.width.px]="width"
			[style.height.px]="height"
		/>
  `,
})
export class AvatarComponent {

  @Input() class: string | Object = {}
  @Input() width: number = 32
  @Input() height: number = 32
  @Input() user: Profile
  @Input() index = 0
  @Input() src: string = ''

  get fallback() {
    return this.user ? `./assets/img/avatar_${this.user.gender}_${this.index % 4}.jpeg` : './assets/img/avatar_anonymous.svg'
  }
}
