import {Directive, HostListener} from '@angular/core'

import {rnNavigateBack} from '@core/utils/rn-webview'

@Directive({
  standalone: true,
  selector: '[quit]',
})
export class QuitDirective {

  @HostListener('click')
  onClick() {
    rnNavigateBack()
  }
}
