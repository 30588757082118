import {Component, OnDestroy} from '@angular/core'
import {opacityAnimation} from "@core/animations/opacity"
import {CommonModule} from "@angular/common"
import {IconComponent} from "@shared/components/icon.component"
import {AppState} from "../../../app.state"
import {Countdown} from "@core/utils/countdown"
import {InsetsDirective} from "@shared/directives/insets.directive"
import moment from "moment"
import {MathjaxHtmlDirective} from "@shared/directives/mathjax-html.directive"
import {RouterLink} from "@angular/router"
import {QuitDirective} from '@shared/directives/quit.directive'

@Component({
  selector: 'question-countdown',
  standalone: true,
  imports: [CommonModule, IconComponent, InsetsDirective, MathjaxHtmlDirective, RouterLink, QuitDirective],
  animations: [opacityAnimation],
  templateUrl: './countdown.component.html'
})
export class QuestionCountdownComponent extends Countdown implements OnDestroy {
  progress: number = 0
  duration: number = 0

  constructor(
    public state: AppState
  ) {
    super()
    this.initCountdown()
  }

  ngOnDestroy(): void {
    this.stopCountdown()
  }

  initCountdown(): void {
    this.state.test$.subscribe((test) => {
      if (!test) return
      this.stopCountdown()

      this.countdown = test.timer__in > 0 ? Math.floor(test.timer__in) : 0
      this.duration = moment.duration(test.time).asSeconds()
      this.startCountdown()
      // const timeLeft = moment.duration(moment(test.timer).diff(now), 'milliseconds').asSeconds().toFixed(0)
      // this.startCountdown(+timeLeft)
      // if (this.state.quiz.mode === QuizMode.INDIVIDUAL && +timeLeft > 0) {
      //   setTimeout(async () => await firstValueFrom(this.state.getTest(this.state.quiz.guid)), +timeLeft > 0 ? +timeLeft * 1000 : -(+timeLeft) * 1000)
      // }
    })
  }

  onFinishCountdown(): void {
    this.stopCountdown()
  }
}
