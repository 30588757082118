<question-countdown></question-countdown>

@if (state.question && state.question.explanation) {
	<div class="page-body flex flex-col gap-5 p-4 max-w-xl mx-auto">
		<div class="bg-[#F0F2FD] flex flex-col gap-4 justify-between items-start p-4 rounded-xl">
			<div class="p-1 w-7 h-7 rounded-full bg-white shadow-lg">
				<mat-icon class="text-[20px] leading-5 text-primary-700">lightbulb</mat-icon>
			</div>
			<div [innerMathjaxHTML]="state.question.explanation"></div>
		</div>
	</div>
	<div insets [paddingBottom]="true" [@opacityAnimation]="state.question" class="flex justify-center shadow-top bg-white">
		<div class="flex justify-between w-full max-w-xl p-4">
			<button
				routerLink="/{{state.quiz.guid}}/test"
				class="w-full flex gap-1 items-center justify-center text-center bg-primary-700 hover:bg-primary-700 disabled:opacity-70 text-white py-3 px-4 rounded-xl text-base transition-all max-w-xl"
			>
				Далее
			</button>
		</div>
	</div>
}
