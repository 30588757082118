export interface ListResponse<T> {
  results: T[]
  count: number
  next: any
  prev?: any
}

export const emptyListResponse = {
  results: [],
  count: 0,
  next: null,
}
