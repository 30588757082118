import {Injectable} from '@angular/core'
import {BehaviorSubject, Observable} from "rxjs"

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  dialog$: BehaviorSubject<any> = new BehaviorSubject<any>(false)

  constructor() { }

  show(options: any): void {
    this.dialog$.next(options)
  }
}
