import {Student} from "./student.model"

export enum LessonAttendanceStatus {
  ABSENT = 0,
  PRESENT = 1
}

export interface LessonAttendance {
  id: number
  created_at: string
  created_by_id: number
  lesson_id: number
  student_id: number
  status: LessonAttendanceStatus
  reason: string
  reason_created_by_id: number
  reason_created_at: string
  date: string
  group_id: number

  // custom
  student?: Student
  online?: boolean
}
