import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {BehaviorSubject, Observable, tap} from 'rxjs'
import amplitude from 'amplitude-js'

import {environment} from "@env"
import {StaffMemberProfile, User} from "@core/schemas/user.model"

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  private _user$ = new BehaviorSubject<User>(null)

  constructor(
    private http: HttpClient,
  ) {
  }

  getUser(): Observable<User> {
    return this.http.get<User>(`${environment.api_hs_sms_url}/user/`).pipe(
      tap(user => {
        amplitude.getInstance().setUserId(user.user_id.toString())
        this._user$.next(user)
      }),
    )
  }

  hasRoles(checkingRoles: string[]): boolean {
    if (!checkingRoles?.length || !this.staff) return false
    return this.staff.roles.some(r => checkingRoles.includes(r.role) && (!r.effective_until_in || r.effective_until_in > 0))
  }

  clearState(): void {
    this._user$.next(null)
  }

  get user(): User {
    return this._user$.getValue()
  }

  get isTeacher(): boolean {
    if (!this.staff) return false
    return this.staff.roles.some(r => ['teacher'].includes(r.role) && (!r.effective_until_in || r.effective_until_in > 0))
  }

  get isStudent(): boolean {
    return !!this.user?.student_profile
  }

  get user$(): Observable<User> {
    return this._user$.asObservable()
  }

  get schoolId(): number {
    return this.user.staff_member_profile.school_id
  }

  get staff(): StaffMemberProfile {
    return this.user?.staff_member_profile
  }
}

