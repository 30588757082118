import {Injectable} from '@angular/core'
import {HttpClient} from '@angular/common/http'
import {Observable} from 'rxjs'
import {environment} from "@env"
import {LessonAttendance} from "@core/schemas/attendance.model"
import {Student} from "@core/schemas/student.model"


@Injectable({providedIn: 'root'})
export class LMSService {
  constructor(protected http: HttpClient) { }

  listLessonAttendance(schoolId: number, lessonId: number): Observable<LessonAttendance[]> {
    return this.http.get<LessonAttendance[]>(`${environment.api_hs_lms_url}/schools/${schoolId}/lessons/${lessonId}/attendances/`)
  }

  listStudents(schoolId: number, groupId: number): Observable<Student[]> {
    return this.http.get<Student[]>(`${environment.api_hs_lms_url}/schools/${schoolId}/groups/${groupId}/students/`)
  }
}
