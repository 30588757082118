export enum QuizType {
  TEST = 0
}

export enum QuizStatus {
  DRAFT = 0,
  PUBLISHED = 1,
  READY = 2,
  LIVE = 3,
  FINISHED = 4
}

export enum QuizMode {
  SYNCHRONIZED = 0,
  INDIVIDUAL = 1
}

export enum QuizDifficulty {
  EASY = 0,
  MEDIUM = 1,
  HARD = 2
}

export enum TestStatus {
  CREATED = 0,
  GENERATING = 1,
  GENERATED = 2,
  ERROR = 3
}

export enum Attendance {
  ABSENT = 0,
  PRESENT = 1
}

export enum QuizPlayerStatus {
  WAITLIST = 0,
  JOINED = 1,
  ABSENT = 2,
  KICKED = 3
}

export interface JooUser {
  id: number
  full_name: string
  groups: string[]
  avatar: string
  phone: string
}

export interface Quiz {
  guid: string
  name: string
  status: QuizStatus
  type: QuizType
  mode: QuizMode
  difficulty: QuizDifficulty
  created_at: string
  created_by_id: number
  created_by: JooUser
  question_cnt: number
  meta: {
    school_id?: number
    lesson_id?: number
    group_id?: number
  }
}

export interface QuizTest {
  quiz_id: number
  points: number
  status: TestStatus
  current_question_id: number
  time: string
  timer: string
}

export interface QuizPlayer {
  id: number
  user: JooUser
  user_id: number
  joined_at: string
  started_at: string
  finished_at: string
  kicked_at: string
  status: QuizPlayerStatus
  points: number
  current_question_id: number
  time_spend: string
  attendance?: Attendance
  online?: boolean
}
