import {HttpClient} from "@angular/common/http"
import {Inject, Injectable} from '@angular/core'
import {ListResponse} from "../models/common.model"
import {Quiz, QuizPlayer, QuizTest} from "../models/quiz.model"
import {TestQuestion, TestQuestionPlayerAnswerStatistic, TestStatistic} from "../models/test.model"
import {Observable} from "rxjs"

@Injectable()
export class QuizService {

  constructor(
    protected http: HttpClient,
    @Inject(String) protected api: string
  ) {
  }

  public connect(): Observable<{token: string}> {
    return this.http.post<{token: string}>(`${this.api}/connect/`, {})
  }

  public subscribe(guid: string): Observable<{quiz_token: string, personal_token: string}> {
    return this.http.post<{quiz_token: string, personal_token: string}>(`${this.api}/${guid}/join/`, {})
  }

  public observe(guid: string): Observable<{quiz_token: string, personal_token: string}> {
    return this.http.post<{quiz_token: string, personal_token: string}>(`${this.api}/${guid}/observe/`, {})
  }

  public listQuiz(params?: any): Observable<ListResponse<Quiz>> {
    return this.http.get<ListResponse<Quiz>>(`${this.api}/`, {params})
  }

  public createQuiz(payload: any): Observable<Quiz> {
    return this.http.post<Quiz>(`${this.api}/`, payload)
  }

  public getQuiz(quizGuid: string): Observable<Quiz> {
    return this.http.get<Quiz>(`${this.api}/${quizGuid}/`)
  }

  public updateQuiz(quizGuid: string, payload: any): Observable<Quiz> {
    return this.http.put<Quiz>(`${this.api}/${quizGuid}/`, payload)
  }

  public listLeaderboard(quizGuid: string): Observable<QuizPlayer[]> {
    return this.http.get<QuizPlayer[]>(`${this.api}/${quizGuid}/leaderboard/`)
  }

  public listPlayer(quizGuid: string): Observable<QuizPlayer[]> {
    return this.http.get<QuizPlayer[]>(`${this.api}/${quizGuid}/players/`)
  }

  public kickPlayer(quizGuid: string, playerId: number): Observable<QuizPlayer[]> {
    return this.http.post<QuizPlayer[]>(`${this.api}/${quizGuid}/players/${playerId}/kick/`, {})
  }

  public restorePlayer(quizGuid: string, playerId: number): Observable<QuizPlayer[]> {
    return this.http.post<QuizPlayer[]>(`${this.api}/${quizGuid}/players/${playerId}/restore/`, {})
  }

  public getTest(quizGuid: string): Observable<QuizTest> {
    return this.http.get<QuizTest>(`${this.api}/${quizGuid}/test/`)
  }

  public getTestStatistic(quizGuid: string, params?: any): Observable<TestStatistic> {
    return this.http.get<TestStatistic>(`${this.api}/${quizGuid}/test/statistics/`, {params})
  }

  public listTestAnswerStatistic(quizGuid: string, params?: any): Observable<TestQuestionPlayerAnswerStatistic[]> {
    return this.http.get<TestQuestionPlayerAnswerStatistic[]>(`${this.api}/${quizGuid}/test/statistics/answers/`, {params})
  }

  public listQuestions(quizGuid: string): Observable<TestQuestion[]> {
    return this.http.get<TestQuestion[]>(`${this.api}/${quizGuid}/test/questions/`)
  }

  public createQuestion(quizGuid: string, payload: any): Observable<TestQuestion> {
    return this.http.post<TestQuestion>(`${this.api}/${quizGuid}/test/questions/`, payload)
  }

  public updateQuestion(quizGuid: string, questionId: number, payload: any): Observable<TestQuestion> {
    return this.http.put<TestQuestion>(`${this.api}/${quizGuid}/test/questions/${questionId}/`, payload)
  }

  public deleteQuestion(quizGuid: string, questionId: number): Observable<undefined> {
    return this.http.delete<undefined>(`${this.api}/${quizGuid}/test/questions/${questionId}/`)
  }

  public deleteQuestionAnswer(quizGuid: string, questionId: number, answerId: number): Observable<undefined> {
    return this.http.delete<undefined>(`${this.api}/${quizGuid}/test/questions/${questionId}/answers/${answerId}/`)
  }

  public answerQuestion(quizGuid: string, questionId: number, payload: {answer_id: number, started_at: string, finished_at: string}): Observable<undefined> {
    return this.http.post<undefined>(`${this.api}/${quizGuid}/test/questions/${questionId}/answers/`, payload)
  }

  public playerStartQuiz(quizGuid: string): Observable<undefined> {
    return this.http.post<undefined>(`${this.api}/${quizGuid}/test/start/`, {})
  }

  public publishQuiz(quizGuid: string): Observable<undefined> {
    return this.http.post<undefined>(`${this.api}/${quizGuid}/publish/`, null)
  }

  public activateQuiz(quizGuid: string, payload: any): Observable<undefined> {
    return this.http.post<undefined>(`${this.api}/${quizGuid}/activate/`, payload)
  }

  public startQuiz(quizGuid: string): Observable<undefined> {
    return this.http.post<undefined>(`${this.api}/${quizGuid}/start/`, null)
  }

  public finishQuiz(quizGuid: string): Observable<undefined> {
    return this.http.post<undefined>(`${this.api}/${quizGuid}/finish/`, null)
  }

  public deleteQuiz(quizGuid: string): Observable<undefined> {
    return this.http.delete<undefined>(`${this.api}/${quizGuid}/`)
  }
}
