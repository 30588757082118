import {Component} from '@angular/core'
import {ProfileService} from "@core/services/profile.service"
import {IconComponent} from "@shared/components/icon.component"
import {InsetsDirective} from "@shared/directives/insets.directive"
import {MathjaxHtmlDirective} from "@shared/directives/mathjax-html.directive"
import {DurationPipe} from "@shared/pipes/duration.pipe"
import {AppState} from "../../../../app.state"
import {RouterLink} from "@angular/router"

@Component({
  standalone: true,
  imports: [InsetsDirective, IconComponent, MathjaxHtmlDirective, DurationPipe, RouterLink],
  templateUrl: './details.component.html'
})
export class ResultDetailsComponent {

  constructor(
    public profileService: ProfileService,
    public state: AppState,
  ) {
  }
}
