import {Injectable} from '@angular/core'
import {DialogService} from "@core/services/ui/dialog.service"
import {Observable, ReplaySubject, Subject} from 'rxjs'

declare global {
  interface Window {
    MathJax: {
      Hub: any
    }
  }
}

@Injectable()
export class MathjaxHtmlService {
  private signal: Subject<boolean>

  private mathJaxSrc = 'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.4/MathJax.js?config=TeX-AMS_SVG';

  constructor(
    private dialogService: DialogService
  ) {
    this.signal = new ReplaySubject<boolean>()

    this.registerMathJaxAsync()
      .then(() => this.signal.next(true))
      .catch(error => {
        console.log(error)
        this.dialogService.show({title: 'Ошибка MathJax'})
      })
  }

  private registerMathJaxAsync(): Promise<any> {
    return new Promise((resolve, reject) => {
      const script: HTMLScriptElement = document.createElement('script')
      script.type = 'text/javascript'
      script.src = this.mathJaxSrc
      script.crossOrigin = 'anonymous'
      script.async = true
      script.onload = () => {
        window.MathJax.Hub.Config({
          messageStyle: "none",
          SVG: {
            linebreaks: {automatic: true},
          },
        })
        resolve('')
      }
      script.onerror = error => reject(error)
      document.head.appendChild(script)
    })
  }

  ready(): Observable<boolean> {
    return this.signal
  }

  render(element: HTMLElement, content: string): void {
    const regex = /<span class="math-tex">/
    const hasMath = regex.test(content)
    if (hasMath) {
      element.classList.add('skeleton-loader')
      element.innerHTML = content

      window?.MathJax?.Hub?.Queue(['Typeset', window.MathJax.Hub, element])
      window?.MathJax?.Hub?.Queue(() => element.classList.remove('skeleton-loader'))
    } else {
      element.innerHTML = content
    }
    element.innerHTML = content

  }
}
