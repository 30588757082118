import {Component, Input} from '@angular/core'
import {IconComponent} from "@shared/components/icon.component"
import {MathjaxHtmlDirective} from "@shared/directives/mathjax-html.directive"
import {DurationPipe} from "@shared/pipes/duration.pipe"
import {AppState} from "../../../../app.state"
import {TestQuestion} from "@quiz"
import {hideVerticalAnimation} from "@core/animations/hide-vertical"

@Component({
  standalone: true,
  selector: 'question-view',
  imports: [IconComponent, MathjaxHtmlDirective, DurationPipe],
  animations: [hideVerticalAnimation],
  templateUrl: './question.component.html',
})
export class QuizQuestionComponent {
  @Input() index: number
  @Input() question: TestQuestion
  collapsed: boolean = false

  constructor(
    public state: AppState,
  ) { }

  onToggle() {
    this.collapsed = !this.collapsed
  }
}
