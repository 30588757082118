import {style, animate, trigger, transition, state} from '@angular/animations'

export const openCloseAnimation = trigger('show', [
  state('show', style({
    marginRight: '16px',
    opacity: 1
  })),
  state('hide', style({
    width: '0px',
    marginRight: '0px',
    opacity: 0.5
  })),
  transition('show <=> hide', animate('0.25s ease-in-out'))
])
