import {Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core'
import {IconComponent} from "@shared/components/icon.component"
import {AppState} from "../../app.state"
import {InsetsDirective} from "@shared/directives/insets.directive"
import {firstValueFrom} from "rxjs"
import {RouterLink} from "@angular/router"
import {ProfileService} from "@core/services/profile.service"
import {AvatarComponent} from "@shared/components/avatar.component"
import {AsyncPipe} from "@angular/common"
import {QuizPlayer} from "@quiz"
import {animate, style, transition, trigger} from "@angular/animations"
import {opacityAnimation} from "@core/animations/opacity"
import {QuitDirective} from '@shared/directives/quit.directive'

@Component({
  standalone: true,
  imports: [IconComponent, InsetsDirective, AvatarComponent, RouterLink, AsyncPipe, QuitDirective],
  animations: [
    opacityAnimation,
    trigger('firstPlace', [
      transition(':enter', [
        style({position: 'absolute', top: "100%", opacity: 0}),
        animate('700ms 1400ms', style({position: 'absolute', top: 0, opacity: 1})),
      ])
    ]),
    trigger('secondPlace', [
      transition(':enter', [
        style({position: 'absolute', top: 0, opacity: 0, left: "-50%"}),
        animate('700ms 700ms', style({position: 'absolute', top: 0, opacity: 1, left: 0})),
      ])
    ]),
    trigger('thirdPlace', [
      transition(':enter', [
        style({position: 'absolute', top: 0, opacity: 0, left: "100%"}),
        animate('700ms', style({position: 'absolute', top: 0, opacity: 1, left: 0})),
      ])
    ]),
    trigger('leaderboard', [
      transition(':enter', [
        style({opacity: 0}),
        animate('200ms 2100ms', style({opacity: 1})),
      ])
    ])
  ],
  templateUrl: './leaderboard.component.html'
})
export class LeaderboardComponent {
  @ViewChild('header') headerElement!: ElementRef
  public isScrolled = false
  private scrollThreshold = 0

  players: QuizPlayer[] = []

  constructor(
    public state: AppState,
    public profileService: ProfileService,
    private renderer: Renderer2
  ) { }

  async ngAfterViewInit() {
    if (!this.state.quiz) return
    await firstValueFrom(this.state.listPlayers(this.state.quiz.guid))
    await firstValueFrom(this.state.listQuestions(this.state.quiz.guid))
    this.checkScroll()
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    this.checkScroll()
  }

  private checkScroll() {
    const scrollPosition = window.pageYOffset

    if (scrollPosition > this.scrollThreshold) {
      if (!this.isScrolled) {
        this.renderer.addClass(this.headerElement.nativeElement, 'bg-white')
        this.renderer.addClass(this.headerElement.nativeElement, 'bg-opacity-50')
        this.renderer.addClass(this.headerElement.nativeElement, 'backdrop-blur-sm')
        this.isScrolled = true
      }
    } else {
      if (this.isScrolled) {
        this.renderer.removeClass(this.headerElement.nativeElement, 'bg-white')
        this.renderer.removeClass(this.headerElement.nativeElement, 'bg-opacity-50')
        this.renderer.removeClass(this.headerElement.nativeElement, 'backdrop-blur-sm')
        this.isScrolled = false
      }
    }
  }
}
