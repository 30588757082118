import {Component, OnInit} from '@angular/core'
import {AppState} from "../../../app.state"
import {IconComponent} from "@shared/components/icon.component"
import {HeaderTeacherComponent} from "../header/header.component"
import {FormGroup, FormsModule} from "@angular/forms"
import {QuizMode, QuizPlayer, QuizPlayerStatus} from "@quiz"
import {combineLatest, firstValueFrom} from "rxjs"
import {CommonModule} from "@angular/common"
import {Router} from "@angular/router"
import {InsetsDirective} from "@shared/directives/insets.directive"
import {AvatarComponent} from "@shared/components/avatar.component"
import {Dialog} from "@angular/cdk/dialog"
import {StartConfirmComponent} from "./confirm/confirm.component"

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent, AvatarComponent, HeaderTeacherComponent, InsetsDirective, FormsModule],
  templateUrl: './start.component.html',
})
export class StartComponent implements OnInit {
  mode: QuizMode = QuizMode.SYNCHRONIZED
  formGroup: FormGroup

  players: QuizPlayer[] = []

  constructor(
    public state: AppState,
    private router: Router,
    private dialog: Dialog
  ) { }

  ngOnInit(): void {
    this.state.quiz$.subscribe(q => {
      if (q) this.mode = q.mode
    })
    combineLatest([this.state.players$, this.state.onlineUsers$]).subscribe(([players, userIds]) => {
      const _players = players.map((p) => ({...p, online: userIds.includes(p.user_id)}))
      this.players = [
        ..._players.filter(p => p.online),
        ..._players.filter(p => !p.online).sort((a, b) => a.status - b.status)
      ]

    })
  }

  get ready(): boolean {
    return this.players.every(p => p.status === QuizPlayerStatus.JOINED)
  }

  get readyCnt(): number {
    return this.players.filter(p => p.status === QuizPlayerStatus.JOINED).length
  }

  async kickPlayer(player: QuizPlayer) {
    await firstValueFrom(this.state.kickPlayer(player))
  }

  async restorePlayer(player: QuizPlayer) {
    await firstValueFrom(this.state.restorePlayer(player))
  }

  async onStart() {
    const onConfirm = async () => {
      await firstValueFrom(this.state.startQuiz(this.mode))
      await firstValueFrom(this.state.getQuiz(this.state.quiz.guid))
      this.router.navigate([`/${this.state.quiz.guid}/statistics`], {replaceUrl: true})
    }

    await firstValueFrom(this.state.listPlayers(this.state.quiz.guid))

    if (this.players.some(p => [QuizPlayerStatus.WAITLIST, QuizPlayerStatus.ABSENT].includes(p.status))) {
      this.dialog.open(StartConfirmComponent, {
        hasBackdrop: true,
        width: '300px',
        data: {onConfirm}
      })
    } else {
      onConfirm()
    }
  }
}
