import {Component} from '@angular/core'
import {RouterLink} from "@angular/router"
import {DialogComponent} from "@shared/components/dialog/dialog.component"
import {IconComponent} from "@shared/components/icon.component"

@Component({
  standalone: true,
  imports: [IconComponent, RouterLink],
  templateUrl: './restricted.component.html',
  styleUrl: './restricted.component.css'
})
export class StudentRestrictedComponent extends DialogComponent {}
