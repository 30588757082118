import {Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core'
import {MathjaxHtmlService} from "@core/services/ui/mathjax-html.service"
import {Subject} from 'rxjs'
import {take, takeUntil} from 'rxjs/operators'

@Directive({
  standalone: true,
  selector: '[innerMathjaxHTML]',
  providers: [MathjaxHtmlService]
})
export class MathjaxHtmlDirective implements OnInit, OnChanges, OnDestroy {
  private alive$ = new Subject<boolean>();

  @Input() innerMathjaxHTML: string
  private readonly htmlElement: HTMLElement

  constructor(
    private service: MathjaxHtmlService,
    private el: ElementRef
  ) {
    this.htmlElement = this.el.nativeElement as HTMLElement
  }

  ngOnInit(): void {
    this.service
      .ready()
      .pipe(
        take(1),
        takeUntil(this.alive$)
      )
      .subscribe(() => {
        this.service.render(this.htmlElement, this.innerMathjaxHTML)
      })
  }

  ngOnChanges(changes: SimpleChanges): void {
    const {currentValue} = changes.innerMathjaxHTML
    this.service.render(this.htmlElement, currentValue)
  }

  ngOnDestroy(): void {
    this.alive$.next(false)
  }
}
